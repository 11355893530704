import get from 'lodash/get';
import * as jobDetailActions from 'src/store/actions/jobDetail';
import * as sharedActions from 'src/store/actions/shared';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const currentUser = useReducerData('auth', 'currentUser.data', {});
  const sectionResult = useReducerData('shared', 'sectionResult.data', {});
  return {
    checkoutLoading: useReducerData('jobDetail', 'checkOutJob.loading', false),
    jobDetail: useReducerData('jobDetail', 'jobDetail.data', {}),
    poDetail: useReducerData('jobDetail', 'poDetail.data', {}),
    companyId: get(currentUser, '[0].companyMaps[0].companyId', ''),
    currentUserId: get(currentUser, '[0].id', ''),
    sectionResult,
  };
};
export const useLoadingData = () => {
  return {
    jobDetailLoading: useReducerData('jobDetail', 'jobDetail.loading', false),
    inspectionSubmitLoading: useReducerData(
      'jobDetail',
      'inspectionSubmit.loading',
      false
    ),
    jobDocumentsLoading: useReducerData(
      'jobDetail',
      'jobDocuments.loading',
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...jobDetailActions,
    ...sharedActions,
  });
};
