import cn from 'classnames';
import classes from './DefectMessage.module.scss';

interface Props {
  className?: string;
  message: string;
  dark?: boolean;
}

const DefectMessage = ({ className, message, dark }: Props) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.dark]: dark,
        },
        className
      )}
    >
      <span>{message}</span>
    </div>
  );
};
export default DefectMessage;
