import find from 'lodash/find';
import {
  FE_JOB_DATE_FORMAT,
  FE_JOB_DATE_TIME_FORMAT,
} from 'src/helpers/constants';
import { getMomentDate } from 'src/utils/date';
import { translateFreeText } from 'src/utils/languagues';

// CurrentUserId to get assignTo inspection dates
export const getJobInfo = (data, t, currentUserId?: number) => {
  const currentAssignInspectionDate =
    find(data.assignTo || [], { userId: currentUserId }) || {};
  const inspectionDates = currentAssignInspectionDate.inspectionDates || [];
  const jobNo = data.jobNoList?.jobNo;
  const startDate = inspectionDates[0]?.date || '';
  const endDate = inspectionDates[inspectionDates.length - 1]?.date || '';
  const dateRange =
    !!startDate && !!endDate
      ? `${getMomentDate(startDate).format(
          FE_JOB_DATE_FORMAT
        )} - ${getMomentDate(endDate).format(FE_JOB_DATE_FORMAT)}`
      : '';
  const dateRangeNumber = Number(data.noOfManday || 0);
  const workdays = data.workdays || [];
  const firstDateList = workdays[0] || '';
  const lastDateList = workdays[workdays.length - 1] || '';
  const dateRangeForList = `${
    firstDateList ? getMomentDate(firstDateList).format(FE_JOB_DATE_FORMAT) : ''
  } - ${
    lastDateList ? getMomentDate(lastDateList).format(FE_JOB_DATE_FORMAT) : ''
  }`;
  const stateName = '';
  const address = data.inspectionFactory?.factory?.address;
  const addressLocal = data.inspectionFactory?.factory?.addressOpt;
  const name = data.inspectionFactory?.factory?.name;
  const nameLocal = data.inspectionFactory?.factory?.nameOpt;
  const factoryId = data.inspectionFactory?.factory?.factoryId;
  const contactPerson =
    data.inspectionFactory?.contactPerson ||
    data.inspectionFactory?.factory?.contactPerson;
  const phone = `${
    data.inspectionFactory?.areaCode ||
    data.inspectionFactory?.factory?.areaCode ||
    ''
  }${
    data.inspectionFactory?.contactPersonPhone ||
    data.inspectionFactory?.factory?.contactNumber ||
    ''
  }`;
  const email =
    data.inspectionFactory?.contactPersonEmail ||
    data.inspectionFactory?.factory?.email ||
    (data.inspectionFactory?.factory?.contactEmails || [])
      .map((obj) => obj.email)
      .join(',');
  const factoryArea = data.inspectionFactory?.factory?.area || {};
  const factoryLocation = [
    factoryArea.city,
    factoryArea.state,
    factoryArea.country,
  ]
    .filter((v) => !!v)
    .join(', ');
  const status = data.status;
  const generalInspectLevel = data.generalInspectLevel || '';
  const typeOfService = data.typeOfService || '';
  const inspectionPOItems =
    data.inspectionPOReportGroup?.inspectionPOItems || [];
  const aqLevelArr = [
    data.criticalLevel
      ? `${translateFreeText('Critical', t)} ${data.criticalLevel}`
      : '',
    data.criticalMajorLevel
      ? `${translateFreeText('Major', t)} ${data.criticalMajorLevel}`
      : '',
    data.criticalMinorLevel
      ? `${translateFreeText('Minor', t)} ${data.criticalMinorLevel}`
      : '',
  ];
  const aqLevel = aqLevelArr.join(', ');
  const aqlLevelSlash = [
    data.criticalLevel || '',
    data.criticalMajorLevel || '',
    data.criticalMinorLevel || '',
  ].join(' / ');
  const tSuppliers = (data.inspectionSuppliers || []).map((obj) => {
    return {
      name: obj?.supplier?.name,
      id: obj?.supplier?.supplierId,
      address:
        [obj?.supplier?.address1, obj?.supplier?.address2]
          .filter((v) => !!v)
          .join(', ') || '',
    };
  });
  return {
    dateRange,
    dateRangeNumber,
    stateName,
    address,
    addressLocal,
    name,
    nameLocal,
    contactPerson,
    phone,
    status,
    email,
    generalInspectLevel: generalInspectLevel.toUpperCase(),
    aqLevel,
    typeOfService,
    inspectionPOItems,
    aqlLevelSlash,
    jobNo,
    dateRangeForList,
    factoryLocation,
    checkinTime: data?.checkinTime
      ? getMomentDate(data.checkinTime).format(FE_JOB_DATE_TIME_FORMAT)
      : '',
    checkoutTime: data?.checkoutTime
      ? getMomentDate(data.checkoutTime).format(FE_JOB_DATE_TIME_FORMAT)
      : '',
    suppliers: tSuppliers.length === 0 ? [{}] : tSuppliers,
    factoryId,
  };
};
