import cn from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BellSvg } from 'src/assets/icons/Bell.svg';
import { Alert, Button, CheckUnCheckSvg } from 'src/components';
import { SECTION_4_NAME, SECTION_5_NAME } from 'src/helpers/constants';
import { useJobDetailStatus } from 'src/helpers/hooks';
import Document from 'src/pages/JobRequests/JobDetail/components/Document';
import { getInspectionContent } from 'src/pages/JobRequests/JobDetail/utils';
import { getRequiredFieldOfStep } from '../SubInspection/components/StepsBar/utils';
import { getFormSettings, getInitialValues } from '../SubInspection/utils';
import classes from './SubSubmissionPreview.module.scss';
import ESign from './components/ESign';
import ESignModal from './components/ESignModal';
import SectionValue from './components/SectionValue';
import { getSubtitle } from './utils';

const ContentPreview = ({ data, shipppingInfos, onEdit }) => {
  const values = getInitialValues(data);

  return (
    <div className={classes.contentPreview}>
      {(data || [])
        .filter((s) => s.enabled)
        .map((section: any) => {
          const stepImdexStr = section.id.replace('step', '');
          const stepIndex = stepImdexStr ? parseInt(stepImdexStr, 10) : 1;
          const fieldObj = getRequiredFieldOfStep(stepIndex, values, data);
          return (
            <SectionValue
              key={section.id}
              title={section.title}
              subTitle={getSubtitle(section)}
              requireSteps={fieldObj.fields}
              completeSteps={fieldObj.completedFields}
              stepNumber={stepIndex}
              values={values}
              data={section}
              isFieldArray={[SECTION_5_NAME, SECTION_4_NAME].includes(
                section.name
              )}
              shipppingInfos={shipppingInfos}
              onEdit={onEdit}
            />
          );
        })}
    </div>
  );
};

const SubSubmissionPreview = ({
  className = '',
  inspectionPOItems,
  calAllInspectionPO,
  needToSubmit = false,
  onSubmit,
  onEdit,
  submitLoading,
  job = null,
}) => {
  const { canSubmitReport } = useJobDetailStatus();
  const { t } = useTranslation(['jobRequests', 'common']);
  const [opened, setOpened] = useState({});
  const [isEsignModal, setIsEsignModal] = useState(false);
  const [signed, setSigned] = useState(null);

  return (
    <div className={cn(classes.wrapper, className)}>
      {needToSubmit && canSubmitReport && (
        <Alert
          message={t('jobDetail.preview.alert')}
          type="error"
          showIcon
          icon={<BellSvg />}
        />
      )}
      {inspectionPOItems.length > 0 && (
        <>
          {inspectionPOItems.map((poItem) => {
            const { requiredFields, completedFields } =
              calAllInspectionPO[poItem.id];
            const tInspectionData = getInspectionContent(poItem);
            const shipppingInfos = poItem.productProfile?.shippingInformation;
            const tFormSettings = getFormSettings(tInspectionData);
            return (
              <div
                key={poItem.id}
                className={cn({
                  [classes.blockOpened]: opened[poItem.id],
                })}
              >
                <Document
                  icon={
                    <CheckUnCheckSvg
                      active={requiredFields === completedFields}
                    />
                  }
                  title={poItem.productProfile?.name}
                  itemNo={poItem.productProfile?.itemNo}
                  des={`${t(
                    'jobDetail.root.completed'
                  )}: ${completedFields}/${requiredFields}`}
                  onMore={() => {
                    setOpened({
                      ...opened,
                      [poItem.id]: !opened[poItem.id],
                    });
                  }}
                  moreRevert
                />
                {opened[poItem.id] && (
                  <ContentPreview
                    data={tFormSettings}
                    shipppingInfos={shipppingInfos}
                    onEdit={(stepNumber) => {
                      onEdit(poItem.id, stepNumber);
                    }}
                  />
                )}
              </div>
            );
          })}
        </>
      )}
      <ESign
        canEdit={needToSubmit}
        onEdit={() => {
          setIsEsignModal(true);
        }}
        // TODO: need to update signature date
        signedDate={
          job?.eSignatureDocumentListId
            ? moment(job.eSignatureFile.createdAt).format('DD MMMM YYYY HH:mm')
            : signed?.date
        }
      />
      {canSubmitReport && (
        <Button
          type={needToSubmit ? 'primary' : 'default'}
          size="large"
          fullWidth
          className={classes.submitBtn}
          disabled={!needToSubmit}
          color={needToSubmit ? '' : 'white'}
          onClick={() => {
            if (needToSubmit) {
              onSubmit(signed);
            }
          }}
          loading={submitLoading}
        >
          {t('buttons.submitInspectionReport', { ns: 'common' })}
        </Button>
      )}
      {!!isEsignModal && (
        <ESignModal
          opened={true}
          onClose={() => {
            setIsEsignModal(false);
          }}
          onSubmit={(values) => {
            setSigned(values);
            setIsEsignModal(false);
          }}
          editObj={{
            ...(signed || {}),
            name: job?.inspectionFactory?.contactPerson,
            companyName: job?.inspectionFactory?.factory?.name,
            rawEsignature: {
              file: job?.eSignatureFile,
              type: job?.eSignatureType,
            },
          }}
        />
      )}
    </div>
  );
};

export default SubSubmissionPreview;
