import cn from 'classnames';
import { useState } from 'react';
import { ReactComponent as ArrowRightSvg } from 'src/assets/icons/ArrowRight.svg';
import { Button, Form, FormItem, Icon, Modal, useForm } from 'src/components';
import { Input, Radio } from 'src/components/FormFields';
import Section from 'src/pages/JobRequests/JobDetail/SubContent/SubInfo/Section';
import FormSection from 'src/pages/JobRequests/JobDetail/components/FormSection';
import { AbsoluteButton } from '../Add1Field';
import MediaField from '../MediaField';
import classes from './ActionCheckField.module.scss';

interface Props {
  className?: string;
  disabled?: boolean;
}
const ActionCheckField = ({ className, disabled }: Props) => {
  const [form] = useForm();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={cn(classes.wrapper, className)}>
      <AbsoluteButton
        headerHadBorder
        onClick={
          disabled
            ? undefined
            : () => {
                setIsOpen(true);
              }
        }
      >
        Edit
        <Icon size={16} notMargin pointer>
          <ArrowRightSvg />
        </Icon>
      </AbsoluteButton>
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        bottom
        title="Barcode Scanning"
      >
        <div className={classes.modalContent}>
          <Form
            form={form}
            name="add-field1-form"
            onFinish={() => {}}
            initialValues={{}}
          >
            <Section boxColor="#FAFAFA" box title="Check Criteria">
              Den makrode ett Joakim Magnusson. Donysk biomebel antedelig.
              Deregisk kokud Madeleine Åkesson pöjaheten. Kvasid ud episo e ett
              Joakim. Den makrode ett Joakim Magnusson. Donysk biomebel
              antedelig. Deregisk kokud Madeleine Åkesson. Den makrode ett
              Joakim Magnusson. Donysk biomebel antedelig. Deregisk kokud
              Madeleine Åkesson
            </Section>
            <FormSection title="Section Result" required onBox>
              <FormItem name={'result'}>
                <Radio
                  name={'result'}
                  options={[
                    {
                      value: 'passed',
                      label: 'Passed',
                    },
                    {
                      value: 'failed',
                      label: 'Failed',
                    },
                    {
                      value: 'pending',
                      label: 'Pending',
                    },
                  ]}
                  fullWidth
                />
              </FormItem>
            </FormSection>
            <FormSection title="Section Remarks" required onBox>
              <FormItem name={'remark'}>
                <Input
                  name={'remark'}
                  placeholder={'Section Remarks'}
                  type={'textarea'}
                  size="large"
                />
              </FormItem>
            </FormSection>
            <FormSection
              title="Reference Media"
              required
              onBox
              des={
                <>
                  <span>
                    Upload <span>at least one</span> media for record. Each file
                    should be smaller than 5MB.
                  </span>
                  <span>Media format: jpg, png, mp4, mov.</span>
                </>
              }
            >
              <FormItem name={'referenceMedia'}>
                <MediaField form={form} name="referenceMedia" />
              </FormItem>
            </FormSection>
            <Button type="primary" fullWidth htmlType="submit" size="large">
              Done
            </Button>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
export default ActionCheckField;
