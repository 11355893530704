import { Input as AntdInput, InputProps } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import cn from 'classnames';
import React from 'react';
import classes from './Input.module.scss';

const { TextArea } = AntdInput;

type Props = InputProps &
  TextAreaProps & {
    className?: string;
    type?: 'text' | 'textarea' | 'password' | 'number';
    label?: string | React.ReactNode;
    hide?: boolean;
    inputRef?: any;
  };

const Input = ({ className, type, label, hide, inputRef, ...other }: Props) => {
  const renderInputEl = () => {
    switch (type) {
      case 'textarea':
        return <TextArea {...other} ref={inputRef} />;
        break;

      default:
        return (
          <AntdInput
            {...other}
            ref={inputRef}
            type={type === 'number' ? 'text' : type}
            onChange={
              type === 'number'
                ? (e) => {
                    if (/^-?\d*(\.\d*)?$/.test(e.target.value)) {
                      other.onChange(e);
                    }
                  }
                : other.onChange
            }
          />
        );
        break;
    }
  };
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          ['hide']: hide,
        },
        className
      )}
    >
      {!!label && <label>{label}</label>}
      {renderInputEl()}
    </div>
  );
};

export default Input;
