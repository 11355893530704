import { Reducer } from 'redux';
// import mockJobs from 'src/mocks/jobs';
import { defaultArr, defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/job';

const initialState = {
  selectedDate: null,
  jobFilters: {},
  jobs: {
    ...defaultObj,
  },
  upcomingJobs: {
    ...defaultObj,
  },
  inprogressJobs: {
    ...defaultObj,
  },
  endedJobs: {
    ...defaultObj,
  },
  datesHasJobs: {
    ...defaultArr,
  },
};

const JobReducer: Reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.SYNC_JOB_FILTERS:
      return {
        ...state,
        jobFilters: { ...(payload || {}) },
      };
    case Types.SYNC_SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: payload,
      };
    case Types.GET_JOBS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          jobs: {
            ...prevState.jobs,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          jobs: {
            loading: false,
            data: {
              ...(payload.data || {}),
              list:
                (meta.payload?.isMore
                  ? [...prevState.jobs.data.list, ...(payload.data?.list || [])]
                  : payload.data?.list) || [],
            },
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          jobs: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_UPCOMING_JOBS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          upcomingJobs: {
            ...prevState.upcomingJobs,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          upcomingJobs: {
            loading: false,
            data: {
              ...(payload.data || {}),
              list:
                (meta.payload?.isMore
                  ? [
                      ...prevState.upcomingJobs.data.list,
                      ...(payload.data?.list || []),
                    ]
                  : payload.data?.list) || [],
            },
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          upcomingJobs: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_INPROGRESS_JOBS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          inprogressJobs: {
            ...prevState.inprogressJobs,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          inprogressJobs: {
            loading: false,
            data: {
              ...(payload.data || {}),
              list:
                (meta.payload?.isMore
                  ? [
                      ...prevState.inprogressJobs.data.list,
                      ...(payload.data?.list || []),
                    ]
                  : payload.data?.list) || [],
            },
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          inprogressJobs: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_ENDED_JOBS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          endedJobs: {
            ...prevState.endedJobs,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          endedJobs: {
            loading: false,
            data: {
              ...(payload.data || {}),
              list:
                (meta.payload?.isMore
                  ? [
                      ...prevState.endedJobs.data.list,
                      ...(payload.data?.list || []),
                    ]
                  : payload.data?.list) || [],
            },
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          endedJobs: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_DATES_HAS_JOBS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          datesHasJobs: {
            ...prevState.datesHasJobs,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          datesHasJobs: {
            loading: false,
            data: payload.data || [],
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          datesHasJobs: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default JobReducer;
