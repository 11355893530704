import { useTranslation } from 'react-i18next';
import { Tag } from 'src/components';
import { STATUS_TEXTS, STATUS_VALUES } from 'src/helpers/constants';

interface Props {
  status: string;
  className?: string;
}

export const statusMapping = {
  [STATUS_VALUES.WAITING_TO_ACCEPT]: 'orange',
  [STATUS_VALUES.COMPLETED]: 'green',
  [STATUS_VALUES.IN_PROGRESS]: 'blue',
  [STATUS_VALUES.SCHEDULED]: 'blue',
  [STATUS_VALUES.REJECTED]: 'red',
  [STATUS_VALUES.SCHEDULED_REJECT]: 'red',
  [STATUS_VALUES.INSPECTION_RELEASED_WAIVER]: 'green',
  [STATUS_VALUES.INSPECTION_RELEASED]: 'green',
};

const JobStatus = ({ status, className }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Tag
      color={statusMapping[status] || 'default'}
      size="small"
      className={className}
    >
      {STATUS_TEXTS[status] ? t(`statuses.${status}`) : status}
    </Tag>
  );
};

export default JobStatus;
