import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'src/components';
import { Input } from 'src/components/FormFields';
import WarningModal from 'src/components/Modal/WarningModal';
import { getMomentDate } from 'src/utils/date';
import classes from './ESignModal.module.scss';
import SignUpload from './SignUpload';
import Signature from './Signature';
import { SIGNATURE_ACTIONS, WARNING_ACTIONS } from './constants';

interface Props {
  opened: boolean;
  onClose: () => void;
  className?: string;
  onSubmit: (values: any) => void;
  editObj?: any;
}

let deleteUploadFileFn = null;

const ESignModal = ({
  opened,
  onClose,
  className,
  onSubmit,
  editObj,
}: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const prefillUrl = editObj?.rawEsignature?.file?.url;
  const needPrefill = !!prefillUrl && !!editObj?.rawEsignature?.type;
  const hasEditObj = !!editObj?.date;
  const [signatureAction, setSignatureAction] = useState(SIGNATURE_ACTIONS.PAD);
  const isSignaturePad = signatureAction === SIGNATURE_ACTIONS.PAD;
  const [confirmAction, setConfirmAction] = useState('');
  const isConfirmSwitchToPad = confirmAction === WARNING_ACTIONS.SWITCH_TO_PAD;
  const isConfirmSwitchToUpload =
    confirmAction === WARNING_ACTIONS.SWITCH_TO_UPLOAD;
  const isConfirmDeleteFile = confirmAction === WARNING_ACTIONS.DELETE_FILE;
  const isConfirmCloseModal = confirmAction === WARNING_ACTIONS.CLOSE_MODAL;
  const [formValues, setFormValues] = useState<any>({});
  const isSubmitDisabled =
    !formValues?.name ||
    !formValues?.title ||
    !formValues?.companyName ||
    (!formValues?.signature && !formValues.signatureFile);
  const resetState = () => {
    if (hasEditObj) {
      setSignatureAction(
        editObj?.signatureFile
          ? SIGNATURE_ACTIONS.UPLOAD
          : SIGNATURE_ACTIONS.PAD
      );
      setFormValues({
        ...editObj,
      });
    } else {
      setSignatureAction(SIGNATURE_ACTIONS.PAD);
      setFormValues(editObj || {});
    }
    deleteUploadFileFn = null;
    setConfirmAction('');
    if (needPrefill) {
      setSignatureAction(
        editObj.rawEsignature.type === 'file'
          ? SIGNATURE_ACTIONS.UPLOAD
          : SIGNATURE_ACTIONS.PAD
      );
    }
  };
  const switchSignatureAction = () => {
    setSignatureAction(
      isSignaturePad ? SIGNATURE_ACTIONS.UPLOAD : SIGNATURE_ACTIONS.PAD
    );
  };
  const warningModalProps = {
    onClose: () => {
      setConfirmAction('');
    },
    isOpen: !!confirmAction,
    children: (
      <span>
        {isConfirmSwitchToPad &&
          t('text.Are you going back to signature pad', { ns: 'common' })}
        {isConfirmSwitchToUpload &&
          t('text.Are you going to upload signature', { ns: 'common' })}
        {isConfirmDeleteFile &&
          t('text.Do you want to delete this file', { ns: 'common' })}
        {isConfirmCloseModal && (
          <>
            {t('text.Are you sure you want to close', { ns: 'common' })}
            <br />
            {t('text.All data will not be saved', { ns: 'common' })}
          </>
        )}
      </span>
    ),
    cancelProps: {
      children: t('buttons.cancel', { ns: 'common' }),
      onClick: () => {
        setConfirmAction('');
      },
    },
    okProps: {
      children: t('text.yes', { ns: 'common' }),
      onClick: () => {
        if (!isConfirmCloseModal) {
          setFormValues({
            ...formValues,
            signature: null,
            signatureFile: null,
          });
          if (isConfirmDeleteFile) {
            if (deleteUploadFileFn) deleteUploadFileFn();
          } else {
            if (
              [
                WARNING_ACTIONS.SWITCH_TO_PAD,
                WARNING_ACTIONS.SWITCH_TO_UPLOAD,
              ].includes(confirmAction)
            ) {
              switchSignatureAction();
            }
          }
          setConfirmAction('');
        } else {
          setConfirmAction('');
          onClose();
        }
      },
    },
  };

  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <>
      <Modal
        open={opened}
        onClose={() => {
          setConfirmAction(WARNING_ACTIONS.CLOSE_MODAL);
        }}
        bottom
        title={t('text.Esignature', { ns: 'common' })}
        className={cn(classes.modalWrapper, className)}
      >
        <div className={classes.modalContent}>
          <p>
            {t('text.Kindly review and ensure the entered information', {
              ns: 'common',
            })}
          </p>
          <div className={classes.esignBlock}>
            {isSignaturePad ? (
              <Signature
                className={classes.signature}
                handleStart={() => {}}
                handleClear={() => {
                  setFormValues({
                    ...formValues,
                    signature: null,
                  });
                }}
                onSignatureChange={(sValue) => {
                  setFormValues({
                    ...formValues,
                    signature: sValue,
                  });
                }}
                canvasProps={{
                  width: 372,
                  height: 164,
                }}
                title={t('text.Signature', { ns: 'common' })}
                prefillUrl={
                  needPrefill && editObj?.rawEsignature?.type !== 'file'
                    ? prefillUrl
                    : ''
                }
              />
            ) : (
              <SignUpload
                title={t('text.Signature', { ns: 'common' })}
                onFileChanged={(tFile) => {
                  setFormValues({
                    ...formValues,
                    signatureFile: tFile,
                  });
                }}
                onDelete={(cb) => {
                  setConfirmAction(WARNING_ACTIONS.DELETE_FILE);
                  deleteUploadFileFn = cb;
                }}
                prefillFile={
                  needPrefill && editObj?.rawEsignature?.type === 'file'
                    ? editObj?.rawEsignature?.file
                    : null
                }
              />
            )}
          </div>
          <div className={classes.switchBlock}>
            <span>
              {t('text.Signature Date', { ns: 'common' })}{' '}
              {!!formValues?.signature?.val || !!formValues?.signatureFile?.name
                ? getMomentDate().format('DD MMMM YYYY HH:mm')
                : '/'}
            </span>
            <div>
              <Button
                type="primary"
                ghost
                size="middle"
                onClick={() => {
                  setConfirmAction(
                    isSignaturePad
                      ? WARNING_ACTIONS.SWITCH_TO_UPLOAD
                      : WARNING_ACTIONS.SWITCH_TO_PAD
                  );
                }}
              >
                {isSignaturePad
                  ? t('text.Switch To Signature Upload', { ns: 'common' })
                  : t('text.Switch To Signature Pad', { ns: 'common' })}
              </Button>
            </div>
          </div>
          <div className={classes.inputBlock}>
            <label>{t('text.Name', { ns: 'common' })}</label>
            <Input
              name="name"
              placeholder={t('text.Enter here', { ns: 'common' })}
              size="large"
              value={formValues?.name}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  name: e.target.value,
                });
              }}
            />
          </div>
          <div className={classes.inputBlock}>
            <label>{t('text.Title', { ns: 'common' })}</label>
            <Input
              name="Title"
              placeholder={t('text.Enter here', { ns: 'common' })}
              size="large"
              value={formValues?.title}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  title: e.target.value,
                });
              }}
            />
          </div>
          <div className={cn(classes.inputBlock, classes.last)}>
            <label>{t('text.Company Name', { ns: 'common' })}</label>
            <Input
              name="companyName"
              placeholder={t('text.Enter here', { ns: 'common' })}
              size="large"
              value={formValues?.companyName}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  companyName: e.target.value,
                });
              }}
            />
          </div>
          <div className={classes.buttons}>
            <Button
              type="ghost"
              size="large"
              onClick={() => {
                setConfirmAction(WARNING_ACTIONS.CLOSE_MODAL);
              }}
            >
              {t('buttons.back', { ns: 'common' })}
            </Button>
            <Button
              type="primary"
              size="large"
              disabled={isSubmitDisabled}
              onClick={() => {
                onSubmit({
                  ...formValues,
                  date: getMomentDate().format('DD MMMM YYYY HH:mm'),
                });
              }}
            >
              {t('buttons.confirm', { ns: 'common' })}
            </Button>
          </div>
        </div>
      </Modal>
      <WarningModal {...warningModalProps} />
    </>
  );
};

export default ESignModal;
