import { SECTION_4_NAME, SECTION_5_NAME } from 'src/helpers/constants';

export const getInitalValues = (data, sectionName) => {
  const initialValues = {} as any;
  if (sectionName === SECTION_4_NAME) {
    initialValues.section_4_result = data.result;
    initialValues.section_4_remark = data.remark;
    initialValues.section_4_referenceMedia = data.referenceMedia;
  } else if (sectionName === SECTION_5_NAME) {
    initialValues.section_5_length = data.length;
    initialValues.section_5_width = data.width;
    initialValues.section_5_height = data.height;
    initialValues.section_5_barcode = data.actualBarcode;
    initialValues.section_5_netWeight = data.netWeight;
    initialValues.section_5_grossWeight = data.grossWeight;
    initialValues.section_5_result = data.result;
    initialValues.section_5_remark = data.remark;
    initialValues.section_5_referenceMedia = data.referenceMedia;
    initialValues.section_5_qty = data.totalQuantity;
    initialValues.section_5_outer_qty = data.outerQuantity;
    initialValues.section_5_outer_no_qty = data.outerCarton;
  }

  return initialValues;
};
const getNumber = (val) => {
  if (!val) return 0;
  return parseFloat(val);
};
export const calculateValue = (val, master, upper, lower) => {
  const nVal = getNumber(val);
  const nMaster = getNumber(master);
  const nUpper = getNumber(upper);
  const nLower = getNumber(lower) * -1;
  const remain = nVal - nMaster;
  const remainPercent =
    parseInt(String(nMaster), 10) === 0 ? nMaster : (remain * 100) / nMaster;
  return {
    color:
      nVal === 0
        ? 'default'
        : remainPercent <= nUpper && remainPercent >= nLower
        ? 'green'
        : 'red',
    val: nVal === 0 ? '-' : nVal,
    remainPercent:
      nVal === 0
        ? '-'
        : remainPercent > 0
        ? `+${remainPercent.toFixed(2)}`
        : remainPercent.toFixed(2),
  };
};

const showOverlay = () => {
  document.getElementById('camera-overlay').classList.add('show');
};
const hideOverlay = () => {
  document.getElementById('camera-overlay').classList.remove('show');
};
const showWarningModal = () => {
  document.querySelector('.scan-warning-modal').classList.add('show');
};
const hideWarningModal = () => {
  document.querySelector('.scan-warning-modal').classList.remove('show');
};
// const turnCameraBack = async () => {
//   const Webcam = (window as any).Webcam;
//   Webcam.set({
//     width: 320,
//     height: 240,
//     image_format: 'jpeg',
//     jpeg_quality: 90,
//     constraints: { facingMode: 'environment' },
//   });
//   Webcam.attach('#video');
// };

export const startScan = (form) => {
  const ZXing = (window as any).ZXing;
  let selectedDeviceId;
  const codeReader = new ZXing.BrowserMultiFormatReader();
  codeReader
    .listVideoInputDevices()
    // .then((videoInputDevices) => {
    .then(() => {
      // selectedDeviceId = videoInputDevices[0].deviceId;
      selectedDeviceId = undefined;
      document.getElementById('barcode-scan').addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (form.getFieldsValue()?.section_5_barcode) {
          showWarningModal();
        } else {
          showOverlay();
          codeReader.decodeFromVideoDevice(
            selectedDeviceId,
            'video',
            (result, err) => {
              if (result) {
                const tCode = result?.text || '';
                if (form && tCode) {
                  form.setFieldValue('section_5_barcode', tCode);
                  codeReader.reset();
                  hideOverlay();
                }
              }
              if (err && !(err instanceof ZXing.NotFoundException)) {
                console.error(err);
              }
            }
          );
        }
      });

      document
        .getElementById('camera-overlay-close')
        .addEventListener('click', () => {
          codeReader.reset();
          hideOverlay();
        });
      document
        .getElementById('warning-cancel-button')
        .addEventListener('click', () => {
          hideWarningModal();
        });
      document
        .getElementById('warning-ok-button')
        .addEventListener('click', () => {
          hideWarningModal();
          showOverlay();
          codeReader.decodeFromVideoDevice(
            selectedDeviceId,
            'video',
            (result, err) => {
              if (result) {
                const tCode = result?.text || '';
                if (form && tCode) {
                  form.setFieldValue('section_5_barcode', tCode);
                  codeReader.reset();
                  hideOverlay();
                }
              }
              if (err && !(err instanceof ZXing.NotFoundException)) {
                console.error(err);
              }
            }
          );
        });
      // document
      //   .getElementById('camera-switch-back')
      //   .addEventListener('click', () => {
      //     turnCameraBack();
      //   });
    })
    .catch(() => {});
};
