import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import { imageUrlToBase64 } from 'src/utils/file';
import classes from './ESignModal.module.scss';

interface DrawSignatureScriptProps {
  onBlur: () => void;
  signRef: any;
  handleClear?: () => void;
  handleStart?: () => void;
  canvasProps: any;
  title: string;
}

const DrawSignatureScript = ({
  canvasProps,
  handleStart,
  handleClear,
  signRef,
  onBlur,
  title,
}: DrawSignatureScriptProps) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const [isFocus, setIsFocus] = useState(false);
  return (
    <div
      className={cn(classes.drawSignature, {
        [classes.isFocus]: isFocus,
      })}
      // onMouseLeave={() => {
      //   setIsFocus(false);
      //   onBlur();
      // }}
    >
      <div className={classes.titleWrapper}>
        <span>{title}</span>
        <span
          onClick={() => {
            if (signRef && signRef.current) {
              signRef.current.clear();
            }
            handleClear();
          }}
        >
          {t('text.Clear Signature', { ns: 'common' })}
        </span>
      </div>
      <>
        <SignatureCanvas
          canvasProps={canvasProps}
          ref={signRef}
          onBegin={() => {
            setIsFocus(true);
            handleStart();
          }}
          onEnd={() => {
            setIsFocus(false);
            onBlur();
          }}
        />
      </>
    </div>
  );
};

interface SignatureProps {
  className?: string;
  canvasProps: any;
  handleStart?: () => void;
  handleClear?: () => void;
  onSignatureChange?: (obj?: any) => void;
  title: string;
  prefillUrl?: string;
}

const Signature = (props: SignatureProps) => {
  const {
    className,
    canvasProps,
    handleStart = () => {},
    handleClear = () => {},
    onSignatureChange = () => {},
    title,
    prefillUrl,
  } = props;
  const signRef = useRef(null);
  const setCanvas = () => {
    imageUrlToBase64(prefillUrl).then((res) => {
      signRef.current.fromDataURL(res, {
        ...canvasProps,
      });
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (signRef && signRef.current && !!prefillUrl) {
        setCanvas();
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cn(classes.signature, className)}>
      <DrawSignatureScript
        title={title}
        canvasProps={canvasProps}
        handleStart={handleStart}
        handleClear={handleClear}
        signRef={signRef}
        onBlur={() => {
          if (signRef && signRef.current) {
            const canvasWidth = String(
              signRef.current.getTrimmedCanvas().getAttribute('width')
            );
            const canvasHeight = String(
              signRef.current.getTrimmedCanvas().getAttribute('height')
            );
            onSignatureChange(
              canvasWidth === '1' && canvasHeight === '1'
                ? null
                : {
                    val: signRef.current
                      .getTrimmedCanvas()
                      .toDataURL('image/png'),
                    type: 'image',
                  }
            );
          }
        }}
      />
    </div>
  );
};

export default Signature;
