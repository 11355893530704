import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BE_REQUEST_DATE_FORMAT } from 'src/helpers/constants';
import useInfiniteScroll from 'src/helpers/hooks/useInfiniteScroll';
import { getMomentDate } from 'src/utils/date';
import Container from '../components/Container';
import EmptyJobs from '../components/EmptyJobs';
import Job from '../components/Job';
import classes from './CalendarView.module.scss';
import JobCalendar from './components/Calendar';
import { useActions, useIndexData } from './selectorData';

let page = 1;

const CalendarView = () => {
  const navigate = useNavigate();
  const { getJobs, getDateHasJobs, syncSetSelectedDate } = useActions();
  const { allJobsData, datesHasJobs, selectedDateStore } = useIndexData();
  const [activeKey, setActiveKey] = useState('');
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const today = getMomentDate();
  const selectedDate = selectedDateStore
    ? getMomentDate(selectedDateStore)
    : today;
  const allJobs = allJobsData.list || [];
  const currentPage = allJobsData?.pagination?.page || 1;
  const totalPage = allJobsData?.pagination?.pages || 1;
  const setSelectedDate = (val) => {
    syncSetSelectedDate(val);
  };
  const reloadJobs = (tDate, tView, cb = null) => {
    getJobs(
      {
        pageNo: page || 1,
        pageSize: 20,
        startDate: tDate.format(BE_REQUEST_DATE_FORMAT),
        endDate: tDate.format(BE_REQUEST_DATE_FORMAT),
      },
      cb
    );
    const isWeekView = tView !== '1';
    const startWeek = tDate.clone().startOf(isWeekView ? 'week' : 'month');
    const endWeek = tDate.clone().endOf(isWeekView ? 'week' : 'month');
    getDateHasJobs({
      startDate: startWeek.format(BE_REQUEST_DATE_FORMAT),
      endDate: endWeek.format(BE_REQUEST_DATE_FORMAT),
    });
  };
  const loadMore = () => {
    setIsLazyLoading(true);
    page = page + 1;
    reloadJobs(selectedDate, activeKey, () => {
      setIsLazyLoading(false);
    });
  };
  useInfiniteScroll({
    onLoadMore: loadMore,
    shouldLoadMore: !isLazyLoading && currentPage < totalPage,
    elementId: 'i-main-layout-wrapper',
  });

  useEffect(() => {
    page = 1;
    reloadJobs(selectedDate, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <JobCalendar
        className={classes.calendar}
        onDateChange={(val) => {
          page = 1;
          reloadJobs(val, activeKey);
        }}
        activeKey={activeKey}
        setActiveKey={(val) => {
          page = 1;
          setActiveKey(val);
          reloadJobs(selectedDate, val);
        }}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        datesHasJobs={datesHasJobs}
      />
      {allJobs.map((job) => {
        const { id } = job;
        return (
          <Job
            key={id}
            data={job}
            onClick={() => {
              navigate(`/jobs/${id}`);
            }}
          />
        );
      })}
      {!allJobs.length && <EmptyJobs />}
    </Container>
  );
};

export default CalendarView;
