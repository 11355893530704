import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components';
import classes from './Title.module.scss';

interface Props {
  className?: string;
  onAdd?: () => void;
}

const Title = ({ className, onAdd }: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  return (
    <div className={cn(classes.wrapper, className)}>
      <span>
        {t('text.Defect List', {
          ns: 'common',
        })}
      </span>
      <Button type="primary" onClick={onAdd} disabled={!onAdd}>
        {t('text.Add Defect', {
          ns: 'common',
        })}
      </Button>
    </div>
  );
};
export default Title;
