import { CloudUploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd/es/upload';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseCircleSvg } from 'src/assets/icons/CloseCircle.svg';
import { ReactComponent as JPGSvg } from 'src/assets/icons/JPG.svg';
import { Dragger } from 'src/components/FormFields';
import toast from 'src/helpers/toast';
import { formatBytes } from 'src/utils/file';
import classes from './ESignModal.module.scss';

interface Props {
  className?: string;
  title: string;
  onFileChanged: (tFile?: any) => void;
  onDelete: (cb?: any) => void;
  prefillFile?: any;
}

const UploadedFileBlock = ({ name = '', size = '', onDelete = null }) => {
  return (
    <div className={classes.uploadedFileBlock}>
      <JPGSvg />
      <span>
        <span>{name}</span>
        <span>{size}</span>
      </span>
      <CloseCircleSvg
        className={classes.uploadedFileDelete}
        onClick={onDelete}
      />
    </div>
  );
};

const SignUpload = (props: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const { className, title, onFileChanged, onDelete, prefillFile } = props;
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedSrc, setUploadedSrc] = useState('');
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const tFile = newFileList[newFileList.length - 1];
    onFileChanged(tFile);
    const tFileName = tFile?.name || '';
    if (tFileName) {
      if (
        tFileName.endsWith('.jpeg') ||
        tFileName.endsWith('.jpg') ||
        tFileName.endsWith('.png')
      ) {
        setUploadedFile(tFile);
        const reader = new FileReader();
        reader.onloadend = function () {
          setUploadedSrc(String(reader.result));
        };
        reader.readAsDataURL(tFile.originFileObj);
      } else {
        toast.error('Please upload image JPG/JPEG/PNG image only');
      }
    } else {
      setUploadedSrc('');
      setUploadedFile(tFile);
    }
  };
  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    onChange: handleChange,
    beforeUpload: () => {
      return false;
    },
    fileList: (uploadedFile ? [uploadedFile] : []).map((f) => ({
      ...f,
      name: !f.name ? f.fileName : f.name,
    })),
  };

  useEffect(() => {
    if (prefillFile) {
      setUploadedFile({
        name: prefillFile.fileName,
        size: Number(prefillFile.fileSize),
      });
      setUploadedSrc(prefillFile.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cn(classes.signUpload, className)}>
      <div className={classes.titleWrapper}>
        <span>{title}</span>
      </div>
      {uploadedSrc ? (
        <div className={classes.signUploadImageWrapper}>
          <img src={uploadedSrc} alt="sign" />
          <UploadedFileBlock
            name={uploadedFile?.name}
            size={formatBytes(uploadedFile?.size)}
            onDelete={() => {
              onDelete(() => {
                setUploadedFile(null);
                setUploadedSrc('');
                onFileChanged(null);
              });
            }}
          />
        </div>
      ) : (
        <Dragger {...uploadProps}>
          <CloudUploadOutlined />
          <p>{t('text.Upload Signature', { ns: 'common' })}</p>
        </Dragger>
      )}
    </div>
  );
};

export default SignUpload;
