export const getInitalValues = (data) => {
  return {
    quantity: data?.quantity || 0,
    description: data?.description || '',
    referenceMedia: data?.referenceMedia || [],
    classification: data?.classification || null,
    defectName: data?.defectName || null,
    defectGroup: data?.defectGroup || null,
  };
};
