import cn from 'classnames';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { FormItem } from 'src/components';
import { Input, Radio } from 'src/components/FormFields';
import {
  FIELD_TYPES,
  PARSED_CUSTOM_FIELDS,
  SECTION_3_NAME,
  SECTION_IGNORE_FIELD_NAMES,
} from 'src/helpers/constants';
import FormSection from 'src/pages/JobRequests/JobDetail/components/FormSection';
import { translateFreeText } from 'src/utils/languagues';
import { capitalize } from 'src/utils/text';
import ActionCheckField from './components/ActionCheckField';
import Add1Field from './components/Add1Field';
import AddField from './components/AddField';
import AttachmentField from './components/AttachmentField';
import MediaField from './components/MediaField';
import Section1ResultCheck from './components/Section1ResultCheck';
import Section3DefectMessage from './components/Section3Elements/DefectMessage';
import Section3EmptyDefects from './components/Section3Elements/EmptyDefects';
import Section3Summary from './components/Section3Elements/Summary';
import Section3Title from './components/Section3Elements/Title';
import {
  getFieldSub,
  getSubColor,
  isSection1InputField,
  setAllSectionPending,
} from './utils';

const SECTION_RESULT_NAME_MAPPING = {
  productDescriptionStyleColor: 'productDescriptionStyleAndColor',
};

const FieldElements = ({
  formEl,
  form,
  thisInspectionData,
  handleAction,
  setIsSavedDraft,
  t,
  onSection3Remove = null,
  job,
  hideSummaryTitleSection3 = false,
  onSection3Add = null,
  fValues = null,
  defectGroupOptions = [],
  isNoDefectFound = false,
  setIsNoDefectFound = null,
  disabled = false,
  formSettings = null,
  onOpenSection1ResultWarning = null,
  sectionResult = null,
}) => {
  const isSection3 = formEl.name === SECTION_3_NAME;
  const tSectionResult =
    sectionResult?.[SECTION_RESULT_NAME_MAPPING[formEl.name] || formEl.name] ||
    [];
  const addedDefects = fValues?.addedDefects || [];
  const tFields = formEl.fields || [];
  const tDefectFields = tFields.filter(
    (obj, index) =>
      obj.name.includes('workmanship_step_defects_') &&
      Number(find(obj.items[index] || [], { name: 'quantity' })?.value) >= 0
  );
  const tOtherFields = tFields.filter(
    (obj) => !obj.name.includes('workmanship_step_defects_')
  );
  const section3DefectFields = [...tDefectFields, ...addedDefects];
  const section3RealDefectFields = section3DefectFields.filter(
    (field) => Number(fValues?.[field.name]?.quantity) >= 0
  );
  return (
    <>
      {!hideSummaryTitleSection3 && (
        <>
          {isSection3 && (
            <Section3Summary
              data={{
                sampleSize: {
                  critical: thisInspectionData?.aql?.critical?.sampleSize,
                  major: thisInspectionData?.aql?.major?.sampleSize,
                  minor: thisInspectionData?.aql?.minor?.sampleSize,
                },
                inspectionLevel: (
                  <span>
                    {`${t('jobDetail.information.generalInspectionLevel')}`}{' '}
                    <span style={{ textTransform: 'uppercase' }}>
                      {job?.generalInspectLevel}
                    </span>
                  </span>
                ),
                aql: {
                  critical: thisInspectionData?.criticalLevel,
                  major: thisInspectionData?.criticalMajorLevel,
                  minor: thisInspectionData?.criticalMinorLevel,
                },
                defectivesAccepted: {
                  critical: thisInspectionData?.aql?.critical?.acceptanceLevel,
                  major: thisInspectionData?.aql?.major?.acceptanceLevel,
                  minor: thisInspectionData?.aql?.minor?.acceptanceLevel,
                },
              }}
            />
          )}
          {isSection3 && (
            <>
              {section3RealDefectFields.length > 0 || isNoDefectFound ? (
                <>
                  <Section3Title onAdd={disabled ? undefined : onSection3Add} />
                  {isNoDefectFound && section3RealDefectFields.length === 0 && (
                    <Section3DefectMessage
                      message={t('text.There is no defect found', {
                        ns: 'common',
                      })}
                    />
                  )}
                </>
              ) : (
                <Section3EmptyDefects
                  onAdd={disabled ? undefined : onSection3Add}
                  onNoFound={
                    disabled
                      ? undefined
                      : () => {
                          if (setIsNoDefectFound) setIsNoDefectFound(true);
                        }
                  }
                />
              )}
            </>
          )}
        </>
      )}
      {(isSection3 ? [...section3DefectFields, ...tOtherFields] : tFields).map(
        (field, j) => {
          const isResultField = field.originalName === 'result';
          const isSection1ResultField =
            field.name === 'quantityAvailable_step_result';
          const hideItem =
            SECTION_IGNORE_FIELD_NAMES.includes(field.originalName) ||
            (isSection3 &&
              field.name.includes('workmanship_step_defects_') &&
              !(Number(fValues?.[field.name]?.quantity) >= 0));
          const commonProps = {
            name: field.name,
            disabled,
          };

          return (
            <React.Fragment key={j}>
              {field.editable === false ? (
                field.content
              ) : (
                <FormItem
                  shouldUpdate
                  className={cn({
                    ['hide']:
                      hideItem ||
                      field.show === false ||
                      field.enabled === false,
                  })}
                >
                  {() => {
                    const values = form.getFieldsValue();
                    // console.log('values', values);
                    const limit = isSection1InputField(formEl.name, field)
                      ? thisInspectionData.orderQty
                      : '';
                    const sub = getFieldSub({
                      sectionName: formEl.name,
                      field,
                      form,
                      limit,
                      t,
                    });
                    const subColor = getSubColor({
                      sectionName: formEl.name,
                      field,
                      fields: formEl.fields,
                      form,
                      limit,
                    });
                    const fieldAction = values[field.name] || {};
                    const fieldHasAction =
                      !isEmpty(fieldAction) &&
                      PARSED_CUSTOM_FIELDS.includes(field.type);
                    const thisTag = translateFreeText(
                      fieldAction?.classification || '',
                      t
                    );
                    const tRadioOptions = (
                      (isResultField ? tSectionResult : field.options) || []
                    ).map((option) => ({
                      ...option,
                      label: translateFreeText(option.label, t),
                    }));
                    return (
                      <FormSection
                        title={translateFreeText(field.title, t)}
                        required={field.required && !thisTag}
                        sub={sub}
                        subColor={subColor}
                        des={field.des}
                        tag={capitalize(thisTag)}
                        limit={limit}
                        borderHeader={[
                          FIELD_TYPES.ADD,
                          FIELD_TYPES.ACTION_CHECK,
                        ].includes(field.type)}
                        actionOnHeader={
                          [FIELD_TYPES.ADD1, FIELD_TYPES.ACTION_CHECK].includes(
                            field.type
                          ) || fieldHasAction
                        }
                        editMode={
                          [FIELD_TYPES.ACTION_CHECK].includes(field.type) ||
                          fieldHasAction
                        }
                        onRemove={
                          isSection3 && field.type === FIELD_TYPES.ADD
                            ? () => {
                                onSection3Remove(field.name, {
                                  ...fieldAction,
                                });
                              }
                            : undefined
                        }
                        disabledRemove={disabled}
                      >
                        <FormItem name={field.name}>
                          {field.type === FIELD_TYPES.ADD ? (
                            <AddField
                              {...commonProps}
                              action={fieldAction}
                              setAction={handleAction(field.name)}
                              field={field}
                              defectGroupOptions={defectGroupOptions}
                              // hide={!(Number(fieldAction?.quantity) >= 0)}
                            />
                          ) : field.type === FIELD_TYPES.ADD1 ? (
                            <Add1Field
                              {...commonProps}
                              action={fieldAction}
                              setAction={handleAction(field.name)}
                              field={field}
                              thisInspectionData={thisInspectionData}
                              sectionResult={tSectionResult}
                            />
                          ) : field.type === FIELD_TYPES.ACTION_CHECK ? (
                            <ActionCheckField {...commonProps} />
                          ) : field.type === FIELD_TYPES.MEDIA ? (
                            <MediaField
                              {...commonProps}
                              setIsSavedDraft={setIsSavedDraft}
                              form={form}
                            />
                          ) : field.type === FIELD_TYPES.ATTACHMENT ? (
                            <AttachmentField
                              {...commonProps}
                              setIsSavedDraft={setIsSavedDraft}
                              form={form}
                            />
                          ) : field.type === FIELD_TYPES.RADIO ? (
                            <Radio
                              {...commonProps}
                              onChange={(e) => {
                                const oldResult = values?.[field.name];
                                if (
                                  isSection1ResultField &&
                                  !!values.section1ResultCheck &&
                                  e.target.value === 'passed' &&
                                  onOpenSection1ResultWarning
                                ) {
                                  onOpenSection1ResultWarning(() => {
                                    form.setFieldValue(field.name, oldResult);
                                    setTimeout(() => {
                                      form.setFieldValue(
                                        'section1ResultCheck',
                                        true
                                      );
                                    }, 100);
                                  });
                                }
                              }}
                              options={
                                isSection1ResultField
                                  ? tRadioOptions.map((obj) => ({
                                      ...obj,
                                      addContent:
                                        obj.value !== 'passed' &&
                                        values.quantityAvailable_step_result ===
                                          obj.value ? (
                                          <Section1ResultCheck
                                            name="section1ResultCheck"
                                            checked={
                                              !!values.section1ResultCheck
                                            }
                                            onChange={() => {
                                              form.setFieldValue(
                                                'section1ResultCheck',
                                                !values.section1ResultCheck
                                              );
                                              if (!values.section1ResultCheck) {
                                                setAllSectionPending(
                                                  form,
                                                  formSettings,
                                                  { setIsNoDefectFound }
                                                );
                                              } else if (
                                                onOpenSection1ResultWarning
                                              ) {
                                                onOpenSection1ResultWarning(
                                                  () => {
                                                    form.setFieldValue(
                                                      'section1ResultCheck',
                                                      true
                                                    );
                                                  }
                                                );
                                              }
                                            }}
                                          />
                                        ) : (
                                          ''
                                        ),
                                    }))
                                  : tRadioOptions
                              }
                              fullWidth
                            />
                          ) : (
                            <Input
                              {...commonProps}
                              placeholder={field.placeholder}
                              type={
                                field.type === 'textarea' ? 'textarea' : 'text'
                              }
                              size="large"
                            />
                          )}
                        </FormItem>
                      </FormSection>
                    );
                  }}
                </FormItem>
              )}
            </React.Fragment>
          );
        }
      )}
    </>
  );
};

export default FieldElements;
