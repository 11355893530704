import find from 'lodash/find';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiGetDocumentList } from 'src/api/shared';
import { ReactComponent as DownloadSvg } from 'src/assets/icons/Download.svg';
import { Button, FormItem } from 'src/components';
import FileIcon from 'src/components/FileIcon';
import { Input, Radio } from 'src/components/FormFields';
import { SECTION_4_CHECK_CRITERIA_FIELD_NAME } from 'src/helpers/constants';
import Section from 'src/pages/JobRequests/JobDetail/SubContent/SubInfo/Section';
import FormSection from 'src/pages/JobRequests/JobDetail/components/FormSection';
import { getListFieldName } from 'src/pages/JobRequests/JobDetail/utils';
import { getFieldListRequired } from 'src/utils/field';
import { formatBytes } from 'src/utils/file';
import { capitalize } from 'src/utils/text';
import MediaField from '../MediaField';
import classes from './Add1Field.module.scss';

const Section4Form = ({
  form,
  field,
  name,
  isSection4 = false,
  sectionResult = [],
}) => {
  const [referenceFiles, setReferenceFiles] = useState([]);
  const { t } = useTranslation(['jobRequests', 'common']);
  const { nameIndex } = getListFieldName(name);
  const thisFields = (field.items || [])[nameIndex] || [];
  const criteriaValue =
    find(thisFields, { name: SECTION_4_CHECK_CRITERIA_FIELD_NAME })?.value ||
    'N/A';
  const referenceFileIds =
    find(thisFields, { name: 'referenceFile' })?.value || [];
  const referenceFileIdsString = JSON.stringify(referenceFileIds);
  const loadReferenceFiles = async (ids) => {
    setReferenceFiles([]);
    if (ids.length > 0) {
      const tRes = await apiGetDocumentList(ids);
      setReferenceFiles(tRes?.data?.data || []);
    }
  };
  useEffect(() => {
    loadReferenceFiles(referenceFileIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceFileIdsString]);

  return (
    <div className={classes.section4Form}>
      <Section
        boxColor="#FAFAFA"
        box
        title={t('text.Check Criteria', { ns: 'common' })}
      >
        {criteriaValue}
      </Section>
      {referenceFiles?.length > 0 && (
        <Section
          boxColor="#FAFAFA"
          box
          title={t('text.Reference File', { ns: 'common' })}
        >
          <div className={classes.referenceFiles}>
            {referenceFiles.map((item, i) => {
              return (
                <div key={i}>
                  <FileIcon fileName={item.fileName} />
                  <span>
                    <span>{item.fileName}</span>
                    <span>{formatBytes(Number(item.fileSize))}</span>
                  </span>
                  <DownloadSvg
                    onClick={() => {
                      const win = window.open(item.url, '_blank');
                      win.focus();
                    }}
                  />
                </div>
              );
            })}
          </div>
        </Section>
      )}
      <FormSection
        title={t('text.Section Result', { ns: 'common' })}
        onBox
        required={getFieldListRequired(thisFields, 'result')}
      >
        <FormItem
          name={'section_4_result'}
          rules={
            getFieldListRequired(thisFields, 'result') && isSection4
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <Radio
            name={'section_4_result'}
            options={sectionResult.map((obj) => ({
              value: obj.value,
              label: t(`text.${capitalize(obj.value)}`, { ns: 'common' }),
            }))}
            fullWidth
          />
        </FormItem>
      </FormSection>
      <FormSection
        title={t('text.Section Remarks', { ns: 'common' })}
        required={getFieldListRequired(thisFields, 'remark')}
        onBox
      >
        <FormItem
          name={'section_4_remark'}
          rules={
            getFieldListRequired(thisFields, 'remark') && isSection4
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <Input
            name={'section_4_remark'}
            placeholder={t('text.Section Remarks', { ns: 'common' })}
            type={'textarea'}
            size="large"
          />
        </FormItem>
      </FormSection>
      <FormSection
        title={t('text.Reference Media', { ns: 'common' })}
        required={getFieldListRequired(thisFields, 'referenceMedia')}
        onBox
        des={
          <>
            <span>{t('form.placeholder.image1', { ns: 'common' })}</span>
            <span>{t('form.placeholder.image2', { ns: 'common' })}</span>
          </>
        }
      >
        <FormItem
          name={'section_4_referenceMedia'}
          rules={
            getFieldListRequired(thisFields, 'referenceMedia') && isSection4
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <MediaField form={form} name="section_4_referenceMedia" />
        </FormItem>
      </FormSection>
      <Button type="primary" fullWidth htmlType="submit" size="large">
        {t('buttons.done', { ns: 'common' })}
      </Button>
    </div>
  );
};
export default Section4Form;
