import cn from 'classnames';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightSvg } from 'src/assets/icons/ArrowRight.svg';
import { ReactComponent as FailedSvg } from 'src/assets/icons/Failed.svg';
import { ReactComponent as PassedSvg } from 'src/assets/icons/Passed.svg';
import { ReactComponent as PendingSvg } from 'src/assets/icons/Pending.svg';
import { ReactComponent as PlusSvg } from 'src/assets/icons/Plus.svg';
import { ReactComponent as ReportAsItSvg } from 'src/assets/icons/ReportAsIt.svg';
import { Button, Form, FormItem, Icon, Modal, useForm } from 'src/components';
import {
  INSPECTION_STATUS_VALUE,
  SECTION_4_CP_NAME_FIELD_NAME,
  SECTION_4_NAME,
  SECTION_5_NAME,
  TYPE_NAME_MAPPING,
} from 'src/helpers/constants';
import { getListFieldName } from 'src/pages/JobRequests/JobDetail/utils';
import { translateFreeText } from 'src/utils/languagues';
import { capitalize } from 'src/utils/text';
import classes from './Add1Field.module.scss';
import Section4Form from './Section4Form';
import Section5Form from './Section5Form';
import Section5Information from './Section5Information';
import { getInitalValues } from './utils';

interface Props {
  className?: string;
  action: any;
  setAction: any;
  field: any;
  name: string;
  thisInspectionData: any;
  disabled?: boolean;
  sectionResult?: any[];
}
const statusIconMapping = {
  [INSPECTION_STATUS_VALUE.PASSED]: <PassedSvg />,
  [INSPECTION_STATUS_VALUE.FAILED]: <FailedSvg />,
  [INSPECTION_STATUS_VALUE.PENDING]: <PendingSvg />,
  [INSPECTION_STATUS_VALUE.REPORT_AS_IT]: <ReportAsItSvg />,
  [INSPECTION_STATUS_VALUE.NOT_APPLICABLE]: <ReportAsItSvg />,
};

export const AbsoluteButton = ({
  children,
  headerHadBorder = false,
  onClick = null,
}) => {
  return (
    <Button
      className={cn(classes.actionBtn, {
        [classes.headerHadBorder]: headerHadBorder,
      })}
      type="link"
      onClick={onClick}
      disabled={!onClick}
    >
      {children}
    </Button>
  );
};
const Add1Field = ({
  className,
  action,
  name,
  field,
  setAction,
  thisInspectionData,
  disabled,
  sectionResult,
}: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const shippingInformation =
    thisInspectionData.productProfile?.shippingInformation || [];
  const { sectionName, nameIndex } = getListFieldName(name);
  const isSection5 = sectionName === SECTION_5_NAME;
  const isSection4 = sectionName === SECTION_4_NAME;
  const [form] = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const commonProps = {
    field,
    name,
    form,
    sectionResult,
  };
  const isNoAction = isEmpty(action);
  const thisFields = (field.items || [])[nameIndex] || [];
  const modalTitle = isSection5
    ? field.label
    : (find(thisFields, { name: SECTION_4_CP_NAME_FIELD_NAME }) || {}).value ||
      'Title';
  let thisShippingInfo = {};
  let section5Type = '';
  if (isSection5) {
    const thisNameArr = name.split('_');
    const thisFieldName = thisNameArr[thisNameArr.length - 1];
    const thisType = TYPE_NAME_MAPPING[thisFieldName];
    section5Type = thisType;
    if (thisType) {
      thisShippingInfo = {
        ...(find(shippingInformation, { type: thisType }) || {}),
      };
    }
  }

  return (
    <div
      className={cn(classes.wrapper, className, {
        hide: field.enabled === false,
      })}
    >
      <AbsoluteButton
        onClick={
          disabled
            ? undefined
            : () => {
                setIsOpen(true);
              }
        }
      >
        {isNoAction
          ? t('buttons.add', { ns: 'common' })
          : t('buttons.edit', { ns: 'common' })}
        <Icon size={16} notMargin pointer>
          {isNoAction ? <PlusSvg /> : <ArrowRightSvg />}
        </Icon>
      </AbsoluteButton>
      {!isNoAction && (
        <div className={classes.content}>
          {!!action?.result && (
            <div className={classes.status}>
              {statusIconMapping[action.result]}
              {t(`text.${capitalize(action.result)}`, { ns: 'common' })}
            </div>
          )}
          {isSection5 && (
            <Section5Information
              thisShippingInfo={thisShippingInfo}
              hideTitle
              values={{
                length: action.length,
                width: action.width,
                height: action.height,
                netWeight: action.netWeight,
                grossWeight: action.grossWeight,
                actualBarcode: action.actualBarcode,
              }}
              fields={field?.fields || []}
            />
          )}
          {!!action?.remark && <div>{action.remark}</div>}
          {action?.referenceMedia && action.referenceMedia.length > 0 && (
            <ul>
              {action?.referenceMedia.map((f, index) => {
                return (
                  <li key={f.uid || f.id || index}>
                    <img src={f.thumbUrl || f.url} alt={f.name} />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
      {isOpen && (
        <Modal
          open={true}
          onClose={() => {
            setIsOpen(false);
          }}
          bottom
          title={translateFreeText(modalTitle, t)}
          className={classes.modalWrapper}
        >
          <div
            className={cn(classes.modalContent, {
              [classes.isSection5]: isSection5,
              [classes.isSection4]: isSection4,
            })}
          >
            <Form
              form={form}
              name="add-field1-form"
              onFinish={(values) => {
                const actionValue = {} as any;
                if (isSection4) {
                  actionValue.result = values.section_4_result;
                  actionValue.remark = values.section_4_remark;
                  actionValue.referenceMedia = values.section_4_referenceMedia;
                } else if (isSection5) {
                  actionValue.length = values.section_5_length;
                  actionValue.width = values.section_5_width;
                  actionValue.height = values.section_5_height;
                  actionValue.actualBarcode = values.section_5_barcode;
                  actionValue.netWeight = values.section_5_netWeight;
                  actionValue.grossWeight = values.section_5_grossWeight;
                  actionValue.result = values.section_5_result;
                  actionValue.remark = values.section_5_remark;
                  actionValue.referenceMedia = values.section_5_referenceMedia;
                  actionValue.totalQuantity = values.section_5_qty;
                  actionValue.outerQuantity = values.section_5_outer_qty;
                  actionValue.outerCarton = values.section_5_outer_no_qty;
                }
                setAction(actionValue);
                setIsOpen(false);
              }}
              initialValues={getInitalValues(action, sectionName)}
            >
              <Section4Form {...commonProps} isSection4={isSection4} />
              <FormItem shouldUpdate>
                {() => {
                  return (
                    <Section5Form
                      {...commonProps}
                      thisShippingInfo={thisShippingInfo}
                      isSection5={isSection5}
                      fieldType={section5Type}
                    />
                  );
                }}
              </FormItem>
            </Form>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default Add1Field;
