import { useParams } from 'react-router-dom';
import { Spin } from 'src/components';
import { useLoadingData } from './selectorData';

const Loading = () => {
  const matchParams = useParams();
  const inspectionId = matchParams?.inspectionId || '';
  const { jobDetailLoading, inspectionSubmitLoading, jobDocumentsLoading } =
    useLoadingData();
  return (
    <Spin
      spinning={
        jobDetailLoading ||
        inspectionSubmitLoading ||
        (jobDocumentsLoading && !!inspectionId)
      }
    />
  );
};

export default Loading;
