import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseSvg } from 'src/assets/icons/Close.svg';
import { ReactComponent as FilterSvg } from 'src/assets/icons/Filter.svg';
import { Tabs } from 'src/components';
import useInfiniteScroll from 'src/helpers/hooks/useInfiniteScroll';
import Container from 'src/pages/JobRequests/components/Container';
import EmptyJobs from 'src/pages/JobRequests/components/EmptyJobs';
import FilterPanel from 'src/pages/JobRequests/components/FilterPanel';
import Job from 'src/pages/JobRequests/components/Job';
import classes from './Jobs.module.scss';
import { DEFAULT_FILTER, FILTER_OPTIONS, TABS } from './constants';
import { useActions, useIndexData } from './selectorData';
import { getJobFilter } from './utils';

let page = 1;

const Jobs = () => {
  const { t } = useTranslation('jobRequests');
  const {
    upcomingJobsData,
    inprogressJobsData,
    endedJobsData,
    jobFiltersStore,
  } = useIndexData();
  const { getUpcomingJobs, getInprogressJobs, getEndedJobs, syncJobFilters } =
    useActions();
  const getActionMapping = {
    [TABS.UP_COMING]: getUpcomingJobs,
    [TABS.IN_PROGRESS]: getInprogressJobs,
    [TABS.ENDED]: getEndedJobs,
  };
  const dataMapping = {
    [TABS.UP_COMING]: upcomingJobsData,
    [TABS.IN_PROGRESS]: inprogressJobsData,
    [TABS.ENDED]: endedJobsData,
  };

  const navigate = useNavigate();
  const [isFilter, setIsFilter] = useState(false);
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const activeTab = jobFiltersStore.activeTab || TABS.UP_COMING;
  const currenFilter =
    jobFiltersStore.currenFilter || DEFAULT_FILTER[TABS.UP_COMING];
  const setActiveTab = (val) => {
    page = 1;
    syncJobFilters({
      activeTab: val,
      currenFilter: DEFAULT_FILTER[val],
    });
    reloadJobs(val, DEFAULT_FILTER[val]);
  };
  const setCurrentFilter = (val) => {
    page = 1;
    syncJobFilters({
      activeTab,
      currenFilter: val,
    });
    reloadJobs(activeTab, val);
  };
  const isInProgressTab = activeTab === TABS.IN_PROGRESS;
  const allJobs = dataMapping[activeTab]?.list || [];
  const currentPage = dataMapping?.[activeTab]?.pagination?.page || 1;
  const totalPage = dataMapping?.[activeTab]?.pagination?.pages || 1;
  const getContent = () => {
    return (
      <Container className={classes.wrapper}>
        {allJobs.map((job) => {
          const { id } = job;
          return (
            <Job
              key={id}
              data={job}
              onClick={() => {
                navigate(`/jobs/${id}`);
              }}
            />
          );
        })}
        {!allJobs.length && <EmptyJobs />}
      </Container>
    );
  };
  const tabs = [
    {
      key: TABS.UP_COMING,
      label: (
        <>
          {/* Upcoming<Badge dot size="small"></Badge> */}
          {t('jobs.tabs.upComing')}
        </>
      ),
      children: getContent(),
    },
    {
      key: TABS.IN_PROGRESS,
      label: <>{t('jobs.tabs.inprogress')}</>,
      children: getContent(),
    },
    {
      key: TABS.ENDED,
      label: t('jobs.tabs.ended'),
      children: getContent(),
    },
  ];
  const handleFilterChange = (tFilter) => () => {
    setCurrentFilter(tFilter);
    setIsFilter(false);
  };
  const reloadJobs = (tActiveTab, tFilter, cb = null) => {
    const thisAction = getActionMapping[tActiveTab];
    if (thisAction) {
      thisAction(
        {
          pageNo: page || 1,
          pageSize: 20,
          ...getJobFilter(tFilter, tActiveTab),
        },
        cb
      );
    }
  };
  const loadMore = () => {
    setIsLazyLoading(true);
    page = page + 1;
    reloadJobs(activeTab, currenFilter, () => {
      setIsLazyLoading(false);
    });
  };
  useInfiniteScroll({
    onLoadMore: loadMore,
    shouldLoadMore: !isLazyLoading && currentPage < totalPage,
    elementId: 'i-main-layout-wrapper',
  });
  useEffect(() => {
    page = 1;
    reloadJobs(activeTab, currenFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classes.tabs}>
        <Tabs
          items={tabs}
          activeKey={activeTab}
          onChange={(val) => {
            setIsFilter(false);
            setActiveTab(val);
          }}
          tabBarExtraContent={{
            right: (
              <>
                {!isInProgressTab && (
                  <span
                    className={cn(classes.filterIcon, {
                      [classes.filterIsNotActive]: !isFilter,
                    })}
                    onClick={() => {
                      setIsFilter(!isFilter);
                    }}
                  >
                    {isFilter ? <CloseSvg /> : <FilterSvg />}
                  </span>
                )}
                {isFilter && (
                  <FilterPanel
                    className={classes.filterPanel}
                    filterList={FILTER_OPTIONS[activeTab]}
                    currentFilter={currenFilter}
                    onFilter={handleFilterChange}
                  />
                )}
              </>
            ),
          }}
        />
      </div>
    </>
  );
};

export default Jobs;
