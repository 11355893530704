export const DEFECT_FIELD_ITEMS = [
  {
    name: 'defectName',
    label: 'Defect Name',
    type: 'LABEL',
    value: '',
    order: 1,
  },
  {
    name: 'defectGroup',
    label: 'Defect Group',
    defaultValue: '',
    value: '',
    order: 2,
  },
  {
    name: 'defectClassification',
    label: 'Defect Classification',
    type: 'LABEL',
    order: 3,
    value: null,
  },
  {
    name: 'referenceMedia',
    label: 'Reference Media',
    placeholder: '',
    type: 'UPLOAD',
    multiple: true,
    optional: false,
    order: 4,
    value: [],
  },
  {
    name: 'quantity',
    label: 'Quantity',
    description: 'Number of individual defect found(Inspector input)',
    placeholder: '',
    type: 'INPUT',
    optional: false,
    order: 5,
    value: null,
  },
  {
    name: 'description',
    label: 'Description',
    placeholder: '',
    type: 'TEXTAREA',
    description: 'Free Text',
    order: 6,
    value: null,
  },
];
export const DEFECT_OBJ = {
  name: 'workmanship_step_defects_',
  title: '',
  label: '',
  type: 'add',
  defaultValue: [],
  value: [],
  items: DEFECT_FIELD_ITEMS,
  order: 1,
  tag: '',
  originalName: 'defects',
  originalType: 'LIST',
  required: true,
  formType: 'add',
};
