import { CameraOutlined } from '@ant-design/icons';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MinusCircleSvg } from 'src/assets/icons/MinusCircle.svg';
import { FormItem } from 'src/components';
import { Upload } from 'src/components/FormFields';
import classes from './MediaField.module.scss';

interface Props {
  className?: string;
  name: string;
  form: any;
  setIsSavedDraft?: (val) => void;
  disabled?: boolean;
}

const UploadButton = ({ disabled = false }) => {
  const { t } = useTranslation(['jobRequests', 'common']);

  return (
    <div
      className={cn(classes.uploadButton, {
        [classes.disabled]: disabled,
      })}
    >
      <CameraOutlined />
      <div>{t('buttons.addMedia', { ns: 'common' })}</div>
    </div>
  );
};
export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error: any) => reject(error);
  });

const MediaField = ({
  className,
  name,
  form,
  setIsSavedDraft,
  disabled,
}: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      const src = await getBase64(file.originFileObj as RcFile);
      const image = new Image();
      image.src = src;
      const w = window.open('');
      w.document.write(image.outerHTML);
    } else if (file.url) {
      const win = window.open(file.url, '_blank');
      win.focus();
    }
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (!disabled) {
      if (setIsSavedDraft) setIsSavedDraft(false);
      form.setFieldValue(name, newFileList);
    }
  };

  return (
    <div className={cn(classes.wrapper, className)}>
      <FormItem shouldUpdate>
        {() => {
          const values = form.getFieldsValue();
          const fileList = (values[name] || []).filter(
            (f) => typeof f !== 'number'
          );
          return (
            <Upload
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              beforeUpload={() => {
                return false;
              }}
              multiple
              itemRender={(originNode, file: any, currFileList) => {
                const fileType = file.type || file.mineType || '';
                const removeButtonEl = (
                  <MinusCircleSvg
                    onClick={
                      disabled
                        ? undefined
                        : (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (setIsSavedDraft) setIsSavedDraft(false);
                            form.setFieldValue(
                              name,
                              currFileList.filter((f: any) =>
                                f.originFileObj && file.originFileObj?.uid
                                  ? file.originFileObj.uid !==
                                    f.originFileObj.uid
                                  : f.id !== file.id
                              )
                            );
                          }
                    }
                    className={cn(classes.deleteIcon, {
                      [classes.disabled]: disabled,
                    })}
                  />
                );
                if (fileType.includes('video/')) {
                  return (
                    <>
                      <video width="150" height="96" controls>
                        <source
                          src={
                            file.url
                              ? file.url
                              : file.originFileObj
                              ? URL.createObjectURL(file.originFileObj)
                              : ''
                          }
                          type={fileType}
                        />
                        {t('text.browserSupportVideo', { ns: 'common' })}
                      </video>
                      {removeButtonEl}
                    </>
                  );
                }
                return (
                  <>
                    {originNode}
                    {removeButtonEl}
                  </>
                );
              }}
              disabled={disabled}
            >
              <UploadButton disabled={disabled} />
            </Upload>
          );
        }}
      </FormItem>
    </div>
  );
};
export default MediaField;
