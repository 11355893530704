import { CloudUploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd/es/upload';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FormItem } from 'src/components';
import { Dragger } from 'src/components/FormFields';
import classes from './AttachmentField.module.scss';

interface Props {
  className?: string;
  form: any;
  name: string;
  setIsSavedDraft?: (val) => void;
  disabled?: boolean;
}

const AttachmentField = ({
  className,
  form,
  name,
  setIsSavedDraft,
  disabled,
}: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (setIsSavedDraft) setIsSavedDraft(false);
    form.setFieldValue(name, newFileList);
  };

  return (
    <div className={cn(classes.wrapper, className)}>
      <FormItem shouldUpdate>
        {() => {
          const values = form.getFieldsValue();
          const fileList = (values[name] || []).filter(
            (f) => typeof f !== 'number'
          );
          const props: UploadProps = {
            name: 'file',
            multiple: true,
            onChange: handleChange,
            beforeUpload: () => {
              return false;
            },
            fileList: fileList.map((f) => ({
              ...f,
              name: !f.name ? f.fileName : f.name,
            })),
            disabled,
          };
          return (
            <Dragger {...props}>
              <CloudUploadOutlined />
              <p>{t('buttons.addAttachment', { ns: 'common' })}</p>
            </Dragger>
          );
        }}
      </FormItem>
    </div>
  );
};
export default AttachmentField;
