export const STATUS_VALUES = {
  WAITING_TO_ACCEPT: 'waitingAccept',
  IN_PROGRESS: 'in-progress',
  COMPLETED: 'completed',
  SCHEDULED: 'scheduled',
  CANCELLED: 'cancelled',
  REJECTED: 'rejected',
  SCHEDULED_REJECT: 'scheduledReject',
  DEFAULT: 'default',
  INSPECTION_RELEASED_WAIVER: 'inspectionReleasedWaiver',
  INSPECTION_RELEASED: 'inspectionReleased',
  INSPECTION_ACCEPTED: 'inspectionAccepted',
  INSPECTION_REJECTED: 'inspectionRejected',
};
export const STATUS_TEXTS = {
  [STATUS_VALUES.WAITING_TO_ACCEPT]: 'Waiting to Accept',
  [STATUS_VALUES.IN_PROGRESS]: 'In Progress',
  [STATUS_VALUES.COMPLETED]: 'Completed',
  [STATUS_VALUES.SCHEDULED]: 'Scheduled',
  [STATUS_VALUES.CANCELLED]: 'Cancelled',
  [STATUS_VALUES.REJECTED]: 'Inspection Rejected',
  [STATUS_VALUES.SCHEDULED_REJECT]: 'Schedule Rejected',
  [STATUS_VALUES.DEFAULT]: 'Default',
  [STATUS_VALUES.INSPECTION_RELEASED_WAIVER]: 'Inspection Released (Waiver)',
  [STATUS_VALUES.INSPECTION_RELEASED]: 'Inspection Released',
  [STATUS_VALUES.INSPECTION_ACCEPTED]: 'Inspection Accepted',
  [STATUS_VALUES.INSPECTION_REJECTED]: 'Inspection Rejected',
};
export const SORT_VALUES = {
  ALL: 'all',
  NEW_TO_OLD: 'new_to_old',
  OLD_TO_NEW: 'old_to_new',
  LAST_3_MONTHS: 'last_3_months',
  LAST_6_MONTHS: 'last_6_months',
};
export const SORT_TEXTS = {
  [SORT_VALUES.ALL]: 'All',
  [SORT_VALUES.NEW_TO_OLD]: 'New to Old (Default)',
  [SORT_VALUES.OLD_TO_NEW]: 'Old to New',
  [SORT_VALUES.LAST_3_MONTHS]: 'Last 3 Months',
  [SORT_VALUES.LAST_6_MONTHS]: 'Last 6 Months',
};
export const INSPECTION_STATUS_VALUE = {
  PASSED: 'passed',
  FAILED: 'failed',
  PENDING: 'pending',
  REPORT_AS_IT: 'report-as-is',
  NOT_APPLICABLE: 'not-applicable',
};
export const INSPECTION_RESULT_VALUE = {
  YES: 'yes',
  NO: 'no',
  CONFORMED: 'conformed',
  NOT_CONFORM: 'not_conform',
  ...INSPECTION_STATUS_VALUE,
};
export const FIELD_TYPES = {
  TEXT: 'text',
  MEDIA: 'media',
  TEXTAREA: 'textarea',
  RADIO: 'radio',
  ADD: 'add',
  ADD1: 'add1',
  ATTACHMENT: 'attachment',
  ACTION_CHECK: 'actionCheck',
};
export const BE_REQUEST_DATE_FORMAT = 'YYYY-MM-DD';
export const FE_JOB_DATE_FORMAT = 'DD MMM YY';
export const FE_JOB_DATE_TIME_FORMAT = 'DD MMM YYYY HH:mm';
export const LIST_TYPES = ['LIST'];
export const UPLOAD_TYPES = ['UPLOAD', 'ATTACHMENT'];
export const INPUT_FIELD = 'INPUT';
export const NORMAL_FIELDS = [INPUT_FIELD, 'TEXTAREA', 'RADIO'];
export const PARSED_UPLOAD_FIELDS = ['media', 'attachment'];
export const PARSED_CUSTOM_FIELDS = [FIELD_TYPES.ADD, FIELD_TYPES.ADD1];
export const FIELD_TYPE_MAPPING = {
  INPUT: FIELD_TYPES.TEXT,
  UPLOAD: FIELD_TYPES.MEDIA,
  TEXTAREA: FIELD_TYPES.TEXTAREA,
  RADIO: FIELD_TYPES.RADIO,
  LIST: FIELD_TYPES.ADD,
  SECTION: FIELD_TYPES.ADD1,
  ATTACHMENT: FIELD_TYPES.ATTACHMENT,
};
export const SECTION_1_LIMIT_FIELD_NAME = 'bookQuantity';
export const SECTION_1_PRODUCED_PERCENT_FIELD_NAME = 'requestProduced';
export const SECTION_1_PRODUCED_PERCENT_INPUT_FIELD_NAME = 'producedPercentage';
export const SECTION_1_PRODUCED_FIELD_NAME = 'producedQuantity';
export const SECTION_1_PACKED_PERCENT_FIELD_NAME = 'requestPacked';
export const SECTION_1_PACKED_PERCENT_INPUT_FIELD_NAME = 'packedPercentage';
export const SECTION_1_PACKED_FIELD_NAME = 'packedQuantity';
export const SECTION_1_IGNORE_FIELD_NAMES = [
  SECTION_1_LIMIT_FIELD_NAME,
  SECTION_1_PRODUCED_PERCENT_FIELD_NAME,
  SECTION_1_PRODUCED_PERCENT_INPUT_FIELD_NAME,
  SECTION_1_PACKED_PERCENT_FIELD_NAME,
  SECTION_1_PACKED_PERCENT_INPUT_FIELD_NAME,
];
export const SECTION_1_NAME = 'quantityAvailable';
export const SECTION_2_NAME = 'productDescriptionStyleColor';
export const SECTION_3_NUMBER_DEFECT_FIELD_NAME = 'numberOfDefect';
export const SECTION_3_DEFECT_NAME_FIELD_NAME = 'defectName';
export const SECTION_3_DEFECT_GROUP_FIELD_NAME = 'defectGroup';
export const SECTION_3_DEFECT_CLASS_FIELD_NAME = 'defectClassification';
export const SECTION_3_DEFECT_NAME = 'defects';
export const SECTION_3_SUB_UPDATE_NAMES = [
  'quantity',
  'referenceMedia',
  'description',
];
export const SECTION_3_NAME = 'workmanship';
export const SECTION_3_IGNORE_FIELD_NAMES = [
  SECTION_3_NUMBER_DEFECT_FIELD_NAME,
];
export const SECTION_4_NAME = 'onSiteTest';
export const SECTION_4_CP_NAME_FIELD_NAME = 'checkPointName';
export const SECTION_4_CHECK_CRITERIA_FIELD_NAME = 'checkCriteria';
export const SECTION_4_CL_FIELD_NAME = 'checkList';
export const SECTION_4_IGNORE_FIELD_NAMES = [
  SECTION_4_CP_NAME_FIELD_NAME,
  SECTION_4_CHECK_CRITERIA_FIELD_NAME,
];
export const SECTION_5_NAME = 'packingMarking';
export const SECTION_6_NAME = 'importantRemarksAttachments';
export const SECTION_5_ADD_FIELD_NAMES = [
  'actualBarcode',
  'grossWeight',
  'height',
  'length',
  'netWeight',
  'referenceMedia',
  'remark',
  'result',
  'width',
  'totalQuantity',
  'outerQuantity',
  'outerCarton',
];
export const SECTION_5_IGNORE_FIELD_NAMES = [
  'dim',
  'barcode',
  'actualNetWeight',
  'actualGrossWeight',
];
export const SECTION_IGNORE_FIELD_NAMES = [
  ...SECTION_1_IGNORE_FIELD_NAMES,
  ...SECTION_3_IGNORE_FIELD_NAMES,
];
export const SUB_ADD_FIELD_NAMES = [
  'quantity',
  'description',
  'result',
  'remark',
  'defectClassification',
];
export const TYPE_NAME_MAPPING = {
  sellingUnitContent: 'selling_unit',
  innerPackContent: 'inner_carton',
  outerCartonContent: 'outer_carton',
};
export const JOB_INFO_SUB_KEY = 'inspectionPOItemSubData';
export const CLASS_OPTIONS = [
  {
    value: 'Critical',
    label: 'Critical',
  },
  {
    value: 'Major',
    label: 'Major',
  },
  {
    value: 'Minor',
    label: 'Minor',
  },
];
