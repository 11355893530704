import { Calendar, Collapse, CollapsePanel } from 'src/components';

import { getMomentDate } from 'src/utils/date';
import classes from './Calendar.module.scss';

interface Props {
  className?: string;
  onDateChange: (val) => void;
  activeKey: string;
  setActiveKey: (val) => void;
  selectedDate: any;
  setSelectedDate: (val) => void;
  datesHasJobs: string[];
}

const JobCalendar = ({
  className,
  activeKey,
  setActiveKey,
  selectedDate,
  setSelectedDate,
  datesHasJobs,
  onDateChange,
}: Props) => {
  return (
    <Collapse
      noStyle
      activeKey={activeKey}
      onClick={() => {
        const newAKey = activeKey === '1' ? '' : '1';
        setActiveKey(newAKey);
      }}
      margin
      box
      className={className}
    >
      <CollapsePanel
        header={
          <div className={classes.calendar}>
            <Calendar
              weekView={activeKey !== '1'}
              value={selectedDate}
              onChange={(date) => {
                onDateChange(date);
                setSelectedDate(date);
              }}
              datesHasJob={datesHasJobs.map((d) => {
                const dateStr = (d || '').split('T')[0];
                return getMomentDate(dateStr).format('DD/MM/YYYY');
              })}
            />
          </div>
        }
        key="1"
      ></CollapsePanel>
    </Collapse>
  );
};

export default JobCalendar;
