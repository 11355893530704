import cn from 'classnames';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import Section from 'src/pages/JobRequests/JobDetail/SubContent/SubInfo/Section';
import { translateFreeText } from 'src/utils/languagues';
import classes from './Add1Field.module.scss';
import { calculateValue } from './utils';

export const CalPercent = ({ data, unit }) => {
  return (
    <>
      <span
        className={cn(classes.calPercent, {
          [classes.isGreen]: data.color === 'green',
          [classes.isRed]: data.color === 'red',
        })}
      >
        {data.remainPercent}%
      </span>{' '}
      / {data.val}
      {unit}
    </>
  );
};
export const Barcode = ({ barcode, actualBarcode }) => {
  return (
    <>
      <span
        className={cn({
          [classes.isYellow]: !!barcode,
        })}
      >
        {barcode || '-'}
      </span>{' '}
      / {actualBarcode}
    </>
  );
};
export const Section5Information = ({
  thisShippingInfo,
  values,
  hideTitle = false,
  fields,
}) => {
  const innerTotalQuantity = find(fields || [], {
    name: 'totalQuantity',
  })?.value;
  const outerTotalQuantity = find(fields || [], {
    name: 'outerQuantity',
  })?.value;
  const outerCarton = find(fields || [], { name: 'outerCarton' })?.value;
  const { t } = useTranslation(['jobRequests', 'common']);
  const calLength = calculateValue(
    values.length,
    thisShippingInfo.length,
    thisShippingInfo.lengthUpperTolerance,
    thisShippingInfo.lengthLowerTolerance
  );
  const calWidth = calculateValue(
    values.width,
    thisShippingInfo.width,
    thisShippingInfo.widthUpperTolerance,
    thisShippingInfo.widthLowerTolerance
  );
  const calHeight = calculateValue(
    values.height,
    thisShippingInfo.height,
    thisShippingInfo.heightUpperTolerance,
    thisShippingInfo.heightLowerTolerance
  );
  const calNetWeight = calculateValue(
    values.netWeight,
    thisShippingInfo.netWeight,
    thisShippingInfo.netWeightUpperTolerance,
    thisShippingInfo.netWeightLowerTolerance
  );
  const calGrossWeight = calculateValue(
    values.grossWeight,
    thisShippingInfo.grossWeight,
    thisShippingInfo.grossWeightUpperTolerance,
    thisShippingInfo.grossWeightLowerTolerance
  );
  const isInnerPack = thisShippingInfo?.type === 'inner_carton';
  const isOuterCarton = thisShippingInfo?.type === 'outer_carton';
  return (
    <Section
      boxColor="#FAFAFA"
      box
      titles={
        hideTitle
          ? undefined
          : [
              t('text.Product Info Reference', { ns: 'common' }),
              t('text.Actual Product Info', { ns: 'common' }),
            ]
      }
      itemsAsArray
      arrayHasBorder
      items={[
        [
          {
            label: t('text.Dim Tolerance (Length)', { ns: 'common' }),
            value: `+${thisShippingInfo.lengthUpperTolerance || 0}% ; -${
              thisShippingInfo.lengthLowerTolerance || 0
            }% / ${thisShippingInfo.length || 0}${translateFreeText(
              thisShippingInfo.lengthUnit,
              t
            )}`,
          },
          {
            label: t('text.Dim Tolerance (Width)', { ns: 'common' }),
            value: `+${thisShippingInfo.widthUpperTolerance || 0}% ; -${
              thisShippingInfo.widthLowerTolerance || 0
            }% / ${thisShippingInfo.width || 0}${translateFreeText(
              thisShippingInfo.widthUnit,
              t
            )}`,
          },
          {
            label: t('text.Dim Tolerance (Height)', { ns: 'common' }),
            value: `+${thisShippingInfo.heightUpperTolerance || 0}% ; -${
              thisShippingInfo.heightLowerTolerance || 0
            }% / ${thisShippingInfo.height || 0}${translateFreeText(
              thisShippingInfo.heightUnit,
              t
            )}`,
          },
          {
            label: t('text.Net Weight Tolerance', { ns: 'common' }),
            value: `+${thisShippingInfo.netWeightUpperTolerance || 0}% ; -${
              thisShippingInfo.netWeightLowerTolerance || 0
            }% / ${thisShippingInfo.netWeight || 0}${translateFreeText(
              thisShippingInfo.netWeightUnit,
              t
            )}`,
          },
          {
            label: t('text.Gross Weight Tolerance', { ns: 'common' }),
            value: `+${thisShippingInfo.grossWeightUpperTolerance || 0}% ; -${
              thisShippingInfo.grossWeightLowerTolerance || 0
            }% / ${thisShippingInfo.grossWeight || 0}${translateFreeText(
              thisShippingInfo.grossWeightUnit,
              t
            )}`,
          },
        ],
        [
          {
            label: t('text.Length', { ns: 'common' }),
            value: (
              <CalPercent
                data={calLength}
                unit={translateFreeText(thisShippingInfo.lengthUnit, t)}
              />
            ),
          },
          {
            label: t('text.Width', { ns: 'common' }),
            value: (
              <CalPercent
                data={calWidth}
                unit={translateFreeText(thisShippingInfo.widthUnit, t)}
              />
            ),
          },
          {
            label: t('text.Height', { ns: 'common' }),
            value: (
              <CalPercent
                data={calHeight}
                unit={translateFreeText(thisShippingInfo.heightUnit, t)}
              />
            ),
          },
          {
            label: t('text.Net Weight', { ns: 'common' }),
            value: (
              <CalPercent
                data={calNetWeight}
                unit={translateFreeText(thisShippingInfo.netWeightUnit, t)}
              />
            ),
          },
          {
            label: t('text.Gross Weight', { ns: 'common' }),
            value: (
              <CalPercent
                data={calGrossWeight}
                unit={translateFreeText(thisShippingInfo.grossWeightUnit, t)}
              />
            ),
          },
        ],
        [
          {
            label: t('text.Barcode', { ns: 'common' }),
            value: (
              <Barcode
                barcode={values.actualBarcode}
                actualBarcode={thisShippingInfo.barcode}
              />
            ),
          },
          ...(isInnerPack
            ? [
                {
                  label: t('text.Total Quantity / Inner Carton', {
                    ns: 'common',
                  }),
                  value:
                    innerTotalQuantity || innerTotalQuantity === 0
                      ? innerTotalQuantity
                      : '',
                  minWidth: 170,
                },
              ]
            : []),
          ...(isOuterCarton
            ? [
                {
                  label: t('text.Total Quantity / Outer Qty', {
                    ns: 'common',
                  }),
                  value:
                    outerTotalQuantity || outerTotalQuantity === 0
                      ? outerTotalQuantity
                      : '',
                },
                {
                  label: t('text.No_of Inner / Outer Carton', {
                    ns: 'common',
                  }),
                  value: outerCarton || outerCarton === 0 ? outerCarton : '',
                  minWidth: 170,
                },
              ]
            : []),
        ],
      ]}
    />
  );
};

export default Section5Information;
