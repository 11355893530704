import { FlagFilled, SearchOutlined } from '@ant-design/icons';
import cn from 'classnames';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SmileSvg } from 'src/assets/icons/Smile.svg';
import {
  Button,
  Form,
  FormItem,
  FormProvider,
  Spin,
  useForm,
} from 'src/components';
import { SECTION_3_NAME } from 'src/helpers/constants';
import { useJobDetailStatus, useStepScroll } from 'src/helpers/hooks';
import ActionModal from 'src/pages/JobRequests/JobDetail/components/CheckIn/ActionModal';
import { translateFreeText } from 'src/utils/languagues';
import { getAllUrlParams } from 'src/utils/route';
import FieldElements from './FieldElements';
import classes from './SubInspection.module.scss';
import DefectModal from './components/DefectModal';
import DefectSearchModal from './components/DefectSearchModal';
import type { StepObjType } from './components/StepsBar';
import StepsBar, { onStepClick } from './components/StepsBar';
import { getFilteredViewSteps } from './components/StepsBar/utils';
import { DEFAULT_STEP_ACTIVE } from './constants';
import {
  getAddedDefectField,
  getInitialValues,
  getSubSection3Options,
  setAllSectionPending,
} from './utils';

interface Props {
  className?: string;
  formSettings: any[];
  inspectionFieldsGroup: any[];
  onForm: any;
  setIsSavedDraft: any;
  thisInspectionData: any;
  nextInspection: any;
  onNext: any;
  jobId: number | string;
  inspectionId: number | string;
  job: any;
  disabled?: boolean;
  sectionResult?: any;
  getSectionResult?: any;
}

const Title = ({ children = null, className = '', ...other }) => {
  return (
    <h1 {...other} className={cn(classes.title, className)}>
      {children}
    </h1>
  );
};
const StepWrapper = ({ children, hide = false }) => {
  return (
    <div
      className={cn(classes.stepWrapper, {
        hide,
      })}
    >
      {children}
    </div>
  );
};
let log = 0;
let resultWarningCb = null;

const SubInspection = ({
  className,
  formSettings,
  // form,
  setIsSavedDraft,
  inspectionFieldsGroup,
  thisInspectionData,
  nextInspection,
  onNext,
  jobId,
  inspectionId,
  onForm,
  job,
  disabled,
  sectionResult,
  getSectionResult,
}: Props) => {
  const { defectGroupOptions } = getSubSection3Options(inspectionFieldsGroup);
  const hideBackToReport = true;
  const [form] = useForm();
  const { t } = useTranslation(['jobRequests', 'common']);
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = getAllUrlParams(location.search) as any;
  const { canBackToReport } = useJobDetailStatus();
  const hasNext = nextInspection && nextInspection.id;
  const [isStepClick, setIsStepClick] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [viewFirst, setViewFirst] = useState(true);
  const [viewLast, setViewLast] = useState(false);
  const [defectSearchModal, setDefectSearchModal] = useState(false);
  const [isConfirmRemove, setIsConfirmRemove] = useState<any>({});
  const [isAddDefect, setIsAddDefect] = useState(false);
  const [isNoDefectFound, setIsNoDefectFound] = useState(false);
  const [isDefectChanged, setIsDefectChanged] = useState(false);
  const [showResultWarning, setShowResultWarning] = useState(false);
  const [step, setStep] = useState({
    active: DEFAULT_STEP_ACTIVE,
  } as StepObjType);
  useStepScroll(step, setStep, isStepClick);
  const [loading, setLoading] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const handleAction = (name) => (tAction) => {
    form.setFieldValue(name, tAction);
  };
  const actionModalProps = {
    isCheck: false,
    setIsCheck: (val) => {
      if (val) {
        const values = form.getFieldsValue();
        const newAllSection3Fields = Object.keys(values).filter((key) =>
          key.includes('workmanship_step_defects_')
        );
        if (newAllSection3Fields.length > 0) {
          const newObjs = {};
          newAllSection3Fields.forEach((key) => {
            newObjs[key] = {
              quantity: '0',
              classification: null,
              description: '',
              referenceMedia: [],
            };
          });
          form.setFieldsValue(newObjs);
        }
        setIsSavedDraft(false);
      }
    },
    isConfirm: isWarning,
    setIsConfirm: (val) => {
      setIsWarning(val);
    },
    onSubmit: () => {},
    icon: <SmileSvg />,
    text: (
      <span className={classes.warningContent}>
        Are you sure you want to mark all defects as “Quantity : 0” ?
      </span>
    ),
    backButtonText: t('buttons.cancel', { ns: 'common' }),
    submitButtonText: t('buttons.confirm', { ns: 'common' }),
  };
  const defectSearchModalProps = {
    opened: defectSearchModal,
    onClose: () => {
      setDefectSearchModal(false);
    },
    formSettings,
    form,
    thisInspectionData,
    setIsSavedDraft,
  };
  const removeModalProps = {
    isOpen: !!isConfirmRemove?.name,
    backButtonText: t('buttons.cancel', { ns: 'common' }),
    submitButtonText: t('buttons.confirm', { ns: 'common' }),
    onBack: () => {
      setIsConfirmRemove({});
    },
    onSubmit: () => {
      form.setFieldValue(isConfirmRemove.name, {
        defectName: isConfirmRemove.data?.defectName,
        defectGroup: isConfirmRemove.data.defectGroup,
      });
      setIsDefectChanged(!isDefectChanged);
      setTimeout(() => {
        setIsConfirmRemove({});
      }, 100);
    },
    icon: <SmileSvg />,
    text: 'Are you sure you want to remove ?',
  };
  const resultWarningProps = {
    isOpen: showResultWarning,
    backButtonText: t('buttons.cancel', { ns: 'common' }),
    submitButtonText: t('buttons.confirm', { ns: 'common' }),
    onBack: () => {
      setShowResultWarning(false);
      if (resultWarningCb) resultWarningCb();
    },
    onSubmit: () => {
      setShowResultWarning(false);
      setAllSectionPending(form, formSettings, {
        setIsNoDefectFound,
        isReset: true,
      });
    },
    icon: <SmileSvg />,
    text: (
      <>
        {t('text.Are you sure you want to change', { ns: 'common' })}
        <br />
        {t('text.Prefilled data will be cleared', { ns: 'common' })}
      </>
    ),
  };

  useEffect(() => {
    log = 0;
    if (urlParams.step) {
      // scroll to step from preview page
      const intStep = parseInt(urlParams.step, 10);
      setScrollLoading(true);
      const filteredViewSteps = getFilteredViewSteps(formSettings);
      if (
        (filteredViewSteps.length === 6 && intStep >= 5) ||
        (filteredViewSteps.length === 5 &&
          filteredViewSteps[filteredViewSteps.length - 1].stepIndex === intStep)
      ) {
        setViewFirst(false);
        setViewLast(true);
      }
      setTimeout(() => {
        onStepClick(intStep);
        navigate(`/jobs/${jobId}/inspection/${inspectionId}`);
        setScrollLoading(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (getSectionResult) getSectionResult();
    // if (form) {
    //   const tValues = form.getFieldsValue();
    //   const tInitialValues = getInitialValues(formSettings);
    //   form.setFieldsValue(tInitialValues);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionId]);
  onForm(form);
  if (log === 0 && formSettings?.length > 0) {
    // console.log('formSettings', formSettings);
    // console.log(
    //   'getInitialValues(formSettings)',
    //   getInitialValues(formSettings)
    // );
    log = 1;
  }

  return (
    <>
      <div className={cn(classes.wrapper, className)}>
        <Spin spinning={scrollLoading} />
        {!!formSettings && formSettings.length > 0 && (
          <FormProvider
            onFormChange={() => {
              setIsSavedDraft(false);
            }}
          >
            <Form
              form={form}
              name="inspection-detail"
              onFinish={() => {}}
              initialValues={getInitialValues(formSettings)}
            >
              <StepsBar
                stepObj={step}
                setStepObj={(tStep) => {
                  setIsStepClick(true);
                  setStep(tStep);
                }}
                form={form}
                inspectionFieldsGroup={inspectionFieldsGroup}
                formSettings={formSettings}
                setIsStepClick={setIsStepClick}
                viewFirst={viewFirst}
                setViewFirst={setViewFirst}
                viewLast={viewLast}
                setViewLast={setViewLast}
              />
              <FormItem name="addedDefects" className="hide">
                <input name="addedDefects" />
              </FormItem>
              <FormItem name="noDefectFound" className="hide">
                <input name="noDefectFound" />
              </FormItem>
              {formSettings.map((formEl, i) => {
                const isSection3 = formEl.name === SECTION_3_NAME;
                const fieldElementProps = {
                  formEl,
                  form,
                  thisInspectionData,
                  handleAction,
                  setIsSavedDraft,
                  t,
                  fValues: form.getFieldsValue(),
                };
                return (
                  <StepWrapper key={i} hide={!formEl.enabled}>
                    <Title id={formEl.id}>
                      {i + 1}. {translateFreeText(formEl.title, t)}
                      {isSection3 && (
                        <SearchOutlined
                          className={classes.defectSearchIcon}
                          onClick={() => {
                            setDefectSearchModal(true);
                          }}
                        />
                      )}
                      {/* {isSection3 && (
                      <Button
                        onClick={() => {
                          setIsWarning(true);
                        }}
                        type="link"
                        textWeight="lg"
                        className={classes.skipAllBtn}
                      >
                        Skip all
                      </Button>
                    )} */}
                    </Title>
                    <FieldElements
                      {...fieldElementProps}
                      onSection3Remove={(fName, fData) => {
                        setIsConfirmRemove({
                          name: fName,
                          data: fData,
                        });
                      }}
                      job={job}
                      onSection3Add={() => {
                        setIsAddDefect(true);
                      }}
                      defectGroupOptions={defectGroupOptions}
                      isNoDefectFound={isNoDefectFound}
                      setIsNoDefectFound={setIsNoDefectFound}
                      disabled={disabled}
                      formSettings={formSettings}
                      onOpenSection1ResultWarning={(cb) => {
                        resultWarningCb = cb;
                        setShowResultWarning(true);
                      }}
                      sectionResult={sectionResult}
                    />
                  </StepWrapper>
                );
              })}
              <div className={classes.endMessage}>
                <FlagFilled />
                {t('jobDetail.inspection.endReport')}
              </div>
              {!hideBackToReport && !disabled && (
                <>
                  {(hasNext || canBackToReport) && (
                    <Button
                      type="primary"
                      fullWidth
                      size="large"
                      loading={loading}
                      onClick={() => {
                        setLoading(true);
                        onNext(form.getFieldsValue(), () => {
                          setLoading(false);
                        });
                      }}
                    >
                      {hasNext
                        ? t('jobDetail.inspection.nextItem')
                        : t('jobDetail.inspection.backToReport')}
                    </Button>
                  )}
                </>
              )}
            </Form>
          </FormProvider>
        )}
        <ActionModal {...actionModalProps} />
        <ActionModal {...removeModalProps} />
        <ActionModal {...resultWarningProps} />
        {defectSearchModal && <DefectSearchModal {...defectSearchModalProps} />}
        <DefectModal
          isOpen={isAddDefect}
          onClose={() => {
            setIsAddDefect(false);
          }}
          onSubmit={(values) => {
            const inspectionValues = form.getFieldsValue();
            const addedDefects = inspectionValues.addedDefects || [];
            const existingLengh =
              (
                find(formSettings || [], { name: SECTION_3_NAME })?.fields || []
              ).filter((obj) => obj.name.includes('workmanship_step_defects_'))
                ?.length + addedDefects.length;
            const addedDefectField = getAddedDefectField(values, existingLengh);
            addedDefects.push(addedDefectField);
            form.setFieldsValue({
              ...inspectionValues,
              addedDefects,
              [`${addedDefectField.name}`]: {
                classification: values.classification,
                description: values.description,
                quantity: values.quantity,
                referenceMedia: values.referenceMedia,
                defectName: values.defectName,
                defectGroup: values.defectGroup,
              },
            });
            setIsDefectChanged(!isDefectChanged);
            setTimeout(() => {
              setIsAddDefect(false);
            }, 100);
          }}
          defectGroupOptions={defectGroupOptions}
        />
      </div>
    </>
  );
};

export default SubInspection;
