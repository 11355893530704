import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as ArrowRightSvg } from 'src/assets/icons/ArrowRight.svg';
import { ReactComponent as ESignSvg } from 'src/assets/icons/ESign.svg';
import classes from './ESign.module.scss';

interface Props {
  className?: string;
  canEdit?: boolean;
  onEdit?: () => void;
  signedDate?: string;
}

const ESign = ({ className, canEdit, onEdit, signedDate }: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);

  return (
    <div className={cn(classes.wrapper, className)}>
      <h3>{t('text.Esignature', { ns: 'common' })}</h3>
      <div className={classes.inner}>
        <ESignSvg />
        <div>
          <span>{t('text.ESignature', { ns: 'common' })}</span>
          <span>
            {signedDate
              ? `${t('statuses.completed', { ns: 'common' })} - ${t(
                  'text.Signature Date',
                  { ns: 'common' }
                )} ${signedDate}`
              : t('text.No Signature', { ns: 'common' })}
          </span>
        </div>
        {canEdit ? (
          <span className={classes.edit} onClick={onEdit}>
            {t('buttons.edit', { ns: 'common' })}
          </span>
        ) : (
          <span className={classes.arrowRightIcon}>
            {/* <ArrowRightSvg /> */}
          </span>
        )}
      </div>
    </div>
  );
};

export default ESign;
