import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import {
  PARSED_CUSTOM_FIELDS,
  SECTION_IGNORE_FIELD_NAMES,
  UPLOAD_TYPES,
} from 'src/helpers/constants';

export const fieldHasValue = (field, value) => {
  if (UPLOAD_TYPES.includes(field.originalType)) {
    return value && isArray(value) && value.length > 0;
  } else if (PARSED_CUSTOM_FIELDS.includes(field.type)) {
    const tKeys = Object.keys(value || {});
    if (
      tKeys.length === 2 &&
      tKeys.includes('defectGroup') &&
      tKeys.includes('defectName')
    ) {
      return false;
    }
    return !!value && !isEmpty(value);
  }
  return !!value;
};
export const getRequiredFieldOfStep = (step, values, formSettings) => {
  const stepData = formSettings[step - 1] || {};
  const stepFields = (stepData.fields || []).filter(
    (field) =>
      !SECTION_IGNORE_FIELD_NAMES.includes(field.originalName) &&
      field.enabled !== false
  );
  const stepRequiredFields = stepFields.filter((field) => {
    return !field.optional && !field.name.includes('workmanship_step_defects_');
  });
  const stepRequiredFieldsLength = stepRequiredFields.length;
  const stepCompletedFields = [
    ...stepRequiredFields.filter((field) => {
      return fieldHasValue(field, values[field.name]);
    }),
  ];
  const stepCompletedFieldsLength = stepCompletedFields.length;
  return {
    fields: stepRequiredFieldsLength,
    completedFields: stepCompletedFieldsLength,
    show: !!stepData.enabled,
  };
};
export const getFilteredViewSteps = (formSettings) => {
  return formSettings
    .map((s, i) => ({
      ...s,
      stepIndex: i + 1,
    }))
    .filter((s) => s.enabled);
};
