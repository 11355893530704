import find from 'lodash/find';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SmileSvg } from 'src/assets/icons/Smile.svg';
import { Button, Form, FormItem, Modal, useForm } from 'src/components';
import { Input, Select } from 'src/components/FormFields';
import ActionModal from 'src/pages/JobRequests/JobDetail/components/CheckIn/ActionModal';
import FormSection from 'src/pages/JobRequests/JobDetail/components/FormSection';
import { translateFreeText } from 'src/utils/languagues';
// import { capitalize } from 'src/utils/text';
import MediaField from '../MediaField';
import classes from './DefectModal.module.scss';
import { getInitalValues } from './utils';

interface Props {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values?: any) => void;
  defectGroupOptions: any[];
}
const DefectModal = ({
  isOpen,
  onClose,
  onSubmit,
  defectGroupOptions,
}: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const [form] = useForm();
  const [isWarning, setIsWarning] = useState(false);
  const actionModalProps = {
    isCheck: false,
    setIsCheck: (val) => {
      if (!val) {
        // Done
        setIsWarning(false);
        const tValues = form.getFieldsValue();
        onSubmit(tValues);
      } else {
        // Stay
        setIsWarning(false);
      }
    },
    isConfirm: isWarning,
    setIsConfirm: () => {},
    onSubmit: () => {},
    icon: <SmileSvg />,
    text: (
      <span className={classes.warningContent}>
        {' '}
        {!form.getFieldsValue().description &&
        (form.getFieldsValue().referenceMedia || []).length === 0
          ? 2
          : 1}{' '}
        {t(
          `text.missingRequiredField${
            !form.getFieldsValue().description &&
            (form.getFieldsValue().referenceMedia || []).length === 0
              ? 's'
              : ''
          }`,
          { ns: 'common' }
        )}
      </span>
    ),
    backButtonText: t('buttons.done', { ns: 'common' }),
    submitButtonText: t('buttons.stay', { ns: 'common' }),
  };
  useEffect(() => {
    if (form) {
      form.setFieldsValue(getInitalValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        bottom
        title={t('jobDetail.section3.reportDefect')}
      >
        <div className={classes.modalContent}>
          <Form
            form={form}
            name="add-field-form"
            onFinish={(values) => {
              const { quantity, description, referenceMedia } = values;
              const intQuantity = quantity ? parseInt(quantity) : 0;
              const thisMedia = referenceMedia || [];
              if (intQuantity > 0 && (!description || thisMedia.length === 0)) {
                setIsWarning(true);
              } else {
                onSubmit(values);
              }
            }}
            initialValues={getInitalValues()}
          >
            <FormItem shouldUpdate className="no-margin">
              {() => {
                const values = form.getFieldsValue();
                const hideTheRest = parseInt(values.quantity || 0, 10) <= 0;
                const nameOptions =
                  find(defectGroupOptions, {
                    value: values.defectGroup,
                  })?.names || [];
                const classOptions =
                  find(nameOptions, { value: values.defectName })
                    ?.classOptions || [];
                return (
                  <>
                    <FormSection
                      title={t('jobDetail.section3.defectGroup')}
                      onBox
                    >
                      <FormItem name={'defectGroup'}>
                        <Select
                          placeholder={t('form.placeholder.select', {
                            ns: 'common',
                          })}
                          size="large"
                          options={defectGroupOptions}
                          onChange={() => {
                            form.setFieldsValue({
                              defectName: null,
                              classification: null,
                              quantity: 0,
                            });
                          }}
                          allowClear
                        />
                      </FormItem>
                    </FormSection>
                    <FormSection
                      title={t('jobDetail.section3.defectName')}
                      onBox
                    >
                      <FormItem name={'defectName'}>
                        <Select
                          placeholder={t('form.placeholder.select', {
                            ns: 'common',
                          })}
                          size="large"
                          options={nameOptions}
                          disabled={!values.defectGroup}
                          allowClear
                          onChange={() => {
                            form.setFieldsValue({
                              classification: null,
                              quantity: 0,
                            });
                          }}
                        />
                      </FormItem>
                    </FormSection>
                    <FormSection
                      title={t('jobDetail.section3.defectClassification')}
                      onBox
                    >
                      <FormItem name={'classification'}>
                        <Select
                          placeholder={t('form.placeholder.select', {
                            ns: 'common',
                          })}
                          size="large"
                          options={classOptions.map((option) => {
                            return {
                              ...option,
                              label: translateFreeText(option.label, t),
                            };
                          })}
                          disabled={!values.defectName}
                          allowClear
                          onChange={(tVal) => {
                            if (!tVal) {
                              form.setFieldsValue({
                                quantity: 0,
                              });
                            }
                          }}
                        />
                      </FormItem>
                    </FormSection>
                    <FormSection
                      title={t('text.Quantity', {
                        ns: 'common',
                      })}
                      onBox
                    >
                      <FormItem name={'quantity'}>
                        <Input
                          name={'quantity'}
                          placeholder={t('text.Quantity', {
                            ns: 'common',
                          })}
                          type={'number'}
                          size="large"
                          disabled={
                            !values.defectGroup ||
                            !values.defectName ||
                            !values.classification
                          }
                        />
                      </FormItem>
                    </FormSection>
                    <FormSection
                      title={t('text.Description', {
                        ns: 'common',
                      })}
                      required
                      onBox
                      hide={hideTheRest}
                    >
                      <FormItem name={'description'}>
                        <Input
                          name={'description'}
                          placeholder={t('text.Description', {
                            ns: 'common',
                          })}
                          type={'textarea'}
                          size="large"
                        />
                      </FormItem>
                    </FormSection>
                    <FormSection
                      title={t('text.Reference Media', {
                        ns: 'common',
                      })}
                      required
                      onBox
                      hide={hideTheRest}
                      des={
                        <>
                          <span>
                            {t('form.placeholder.image1', {
                              ns: 'common',
                            })}
                          </span>
                          <span>
                            {t('form.placeholder.image2', {
                              ns: 'common',
                            })}
                          </span>
                        </>
                      }
                    >
                      <FormItem name={'referenceMedia'}>
                        <MediaField name="referenceMedia" form={form} />
                      </FormItem>
                    </FormSection>
                    <Button
                      type="primary"
                      fullWidth
                      htmlType="submit"
                      size="large"
                      disabled={
                        !values.defectGroup ||
                        !values.defectName ||
                        !values.classification
                      }
                    >
                      {t('buttons.done', { ns: 'common' })}
                    </Button>
                  </>
                );
              }}
            </FormItem>
          </Form>
        </div>
      </Modal>
      <ActionModal {...actionModalProps} />
    </>
  );
};
export default DefectModal;
