import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components';
import DefectMessage from '../DefectMessage';
import classes from './EmptyDefects.module.scss';

interface Props {
  className?: string;
  onAdd?: () => void;
  onNoFound?: () => void;
}

const EmptyDefects = ({ className, onAdd, onNoFound }: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  return (
    <div className={cn(classes.wrapper, className)}>
      <DefectMessage
        message={t('text.Please click the button below', { ns: 'common' })}
        dark
      />
      <div className={classes.buttons}>
        <Button
          type="primary"
          size="large"
          ghost
          onClick={onAdd}
          disabled={!onAdd}
        >
          {t('text.Add Report Defect', { ns: 'common' })}
        </Button>
        <Button
          type="primary"
          size="large"
          ghost
          onClick={onNoFound}
          disabled={!onNoFound}
        >
          {t('text.No Defect Found', { ns: 'common' })}
        </Button>
      </div>
    </div>
  );
};
export default EmptyDefects;
