export const translateFreeText = (key, t) => {
  const tKey = (key || '').trim();
  if (!tKey) return '';
  if (tKey === 'No. of Inner / Outer Carton') {
    return t('text.No_of Inner / Outer Carton', { ns: 'common' });
  }
  const translateKey = `text.${tKey}`;
  const tText = t(translateKey, { ns: 'common' });
  return tText.includes(translateKey) ? tKey : tText;
};
