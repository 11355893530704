import cn from 'classnames';
import find from 'lodash/find';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FailedSvg } from 'src/assets/icons/Failed.svg';
import { ReactComponent as PassedSvg } from 'src/assets/icons/Passed.svg';
import { ReactComponent as PendingSvg } from 'src/assets/icons/Pending.svg';
import { ReactComponent as ReportAsItSvg } from 'src/assets/icons/ReportAsIt.svg';
import { Button } from 'src/components';
import {
  INSPECTION_RESULT_VALUE,
  SECTION_1_IGNORE_FIELD_NAMES,
  SECTION_4_IGNORE_FIELD_NAMES,
  SECTION_4_NAME,
  SECTION_5_IGNORE_FIELD_NAMES,
  SECTION_5_NAME,
} from 'src/helpers/constants';
import { useJobDetailStatus } from 'src/helpers/hooks';
import SubInfoSection from 'src/pages/JobRequests/JobDetail/SubContent/SubInfo/Section';
import { getListFieldName } from 'src/pages/JobRequests/JobDetail/utils';
import { translateFreeText } from 'src/utils/languagues';
import { capitalize } from 'src/utils/text';
import Field from '../Field';
import classes from './SectionValue.module.scss';
import {
  getDisplayFieldLabel,
  getDisplayFieldValue,
  getSection5Value,
} from './utils';

interface Props {
  className?: string;
  title: string;
  subTitle: string;
  requireSteps: number;
  completeSteps: number;
  stepNumber: number;
  values: any;
  data: any;
  isFieldArray?: boolean;
  shipppingInfos: any[];
  onEdit: (step: number) => void;
}

const StepNumber = ({ step }) => {
  return <span className={classes.stepNumber}>{step}</span>;
};

const resultIconMapping = {
  [INSPECTION_RESULT_VALUE.PASSED]: <PassedSvg />,
  [INSPECTION_RESULT_VALUE.YES]: <PassedSvg />,
  [INSPECTION_RESULT_VALUE.CONFORMED]: <PassedSvg />,
  [INSPECTION_RESULT_VALUE.FAILED]: <FailedSvg />,
  [INSPECTION_RESULT_VALUE.NO]: <FailedSvg />,
  [INSPECTION_RESULT_VALUE.NOT_CONFORM]: <FailedSvg />,
  [INSPECTION_RESULT_VALUE.PENDING]: <PendingSvg />,
  [INSPECTION_RESULT_VALUE.REPORT_AS_IT]: <ReportAsItSvg />,
  [INSPECTION_RESULT_VALUE.NOT_APPLICABLE]: <ReportAsItSvg />,
};

const SectionValue = ({
  className,
  title,
  requireSteps,
  completeSteps,
  stepNumber,
  data,
  values,
  subTitle,
  isFieldArray,
  shipppingInfos,
  onEdit,
}: Props) => {
  const { canEditReport } = useJobDetailStatus();
  const { t } = useTranslation(['jobRequests', 'common']);

  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.header}>
        <div>
          <StepNumber step={stepNumber} />
          <h4>{translateFreeText(title, t)}</h4>
        </div>
        <div>
          {requireSteps > 0 && requireSteps === completeSteps && <PassedSvg />}
          <span>
            {completeSteps}/{requireSteps}
          </span>
        </div>
      </div>
      <div className={classes.content}>
        {isFieldArray ? (
          <div className={classes.contentInner}>
            {data.fields.map((parentField, index) => {
              const { sectionName, nameIndex } = getListFieldName(
                parentField.name
              );
              const tFields =
                sectionName === SECTION_4_NAME
                  ? parentField.items[nameIndex]
                  : parentField.fields;

              return (
                <React.Fragment key={parentField.name}>
                  <Field
                    label={`${translateFreeText(parentField.label, t)}${
                      sectionName === SECTION_5_NAME &&
                      parentField.optional !== true
                        ? ` (${t('form.required', { ns: 'common' })})`
                        : ''
                    }`}
                    value={
                      canEditReport ? (
                        <Button
                          className={cn(classes.actionBtn)}
                          type="link"
                          onClick={() => {
                            onEdit(stepNumber);
                          }}
                        >
                          {t('buttons.edit', { ns: 'common' })}
                          {' >'}
                        </Button>
                      ) : (
                        <></>
                      )
                    }
                  />
                  <SubInfoSection
                    className={cn(classes.sectionInsideArray, {
                      [classes.nonBorder]: index === 0,
                    })}
                    items={tFields
                      .filter(
                        (field) =>
                          ![
                            ...SECTION_5_IGNORE_FIELD_NAMES,
                            ...SECTION_4_IGNORE_FIELD_NAMES,
                          ].includes(field.name)
                      )
                      .map((field) => {
                        return {
                          label: getDisplayFieldLabel(
                            field,
                            sectionName === SECTION_5_NAME,
                            t
                          ),
                          minWidth:
                            sectionName === SECTION_5_NAME &&
                            ['totalQuantity', 'outerCarton'].includes(
                              field.name
                            )
                              ? 152
                              : '',
                          value:
                            sectionName === SECTION_5_NAME
                              ? getSection5Value(
                                  field,
                                  parentField,
                                  shipppingInfos,
                                  resultIconMapping,
                                  t
                                )
                              : getDisplayFieldValue(
                                  field,
                                  resultIconMapping,
                                  tFields,
                                  values,
                                  t
                                ),
                        };
                      })}
                  />
                </React.Fragment>
              );
            })}
          </div>
        ) : (
          <>
            {canEditReport && (
              <Field
                label={translateFreeText(subTitle, t)}
                value={
                  <Button
                    className={cn(classes.actionBtn)}
                    type="link"
                    onClick={() => {
                      onEdit(stepNumber);
                    }}
                  >
                    {t('buttons.edit', { ns: 'common' })}
                    {' >'}
                  </Button>
                }
              />
            )}
            <SubInfoSection
              items={data.fields
                .filter(
                  (field) =>
                    !SECTION_1_IGNORE_FIELD_NAMES.includes(field.originalName)
                )
                .map((field) => {
                  const tValue = getDisplayFieldValue(
                    field,
                    resultIconMapping,
                    data.fields,
                    values,
                    t
                  );
                  const needDisplayTag = (field.name || '').includes(
                    'workmanship_step_defects_'
                  );
                  const section3FieldNameIndex = getListFieldName(
                    field.name
                  )?.nameIndex;
                  return {
                    label: getDisplayFieldLabel(
                      {
                        ...field,
                        tag: needDisplayTag
                          ? capitalize(
                              find(field?.items?.[section3FieldNameIndex], {
                                name: 'defectClassification',
                              })?.value || ''
                            )
                          : '',
                      },
                      needDisplayTag,
                      t
                    ),
                    value: tValue,
                  };
                })}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SectionValue;
