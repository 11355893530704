import { Dispatch } from 'redux';
import {
  apiAcceptJob,
  apiCheckInJob,
  apiCheckOutJob,
  apiGetJobDetail,
  apiGetJobDocuments,
  apiGetPODetail,
  apiInspectionSubmit,
  apiRejectJob,
} from 'src/api';

import Types from '../types/jobDetail';

export const syncUpdateJobDetail =
  (jobDetail, force = false) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: Types.SYNC_UPDATE_JOB_DETAIL,
      payload: {
        updatedJobDetail: jobDetail,
        force,
      },
    });
  };
export const syncUpdateJobDocuments = (data) => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_UPDATE_JOB_DOCUMENTS,
    payload: {
      updatedJobDocuments: data,
    },
  });
};

export const getJobDetail =
  (id: string | number, cb?: any, data?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {
        jobId: id,
        silent: data?.silent,
      },
      type: Types.GET_JOB_DETAIL,
      asyncCall: () => {
        return apiGetJobDetail(id);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getPODetail =
  (id: string | number, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {
        jobId: id,
      },
      type: Types.GET_PO_DETAIL,
      asyncCall: () => {
        return apiGetPODetail(id);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getJobDocuments =
  (id: string | number, poId: any, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {
        jobId: id,
      },
      type: Types.GET_JOB_DOCUMENTS,
      asyncCall: () => {
        return apiGetJobDocuments(id, poId);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const rejectJob =
  (id: string | number, reason: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {
        jobId: id,
      },
      type: Types.REJECT_JOB,
      asyncCall: () => {
        return apiRejectJob(id, reason);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const acceptJob =
  (id: string | number, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {
        jobId: id,
      },
      type: Types.ACCEPT_JOB,
      asyncCall: () => {
        return apiAcceptJob(id);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const checkInJob =
  (id: string | number, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {
        jobId: id,
      },
      type: Types.CHECK_IN_JOB,
      asyncCall: () => {
        return apiCheckInJob(id);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const checkOutJob =
  (id: string | number, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {
        jobId: id,
      },
      type: Types.CHECK_OUT_JOB,
      asyncCall: () => {
        return apiCheckOutJob(id);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const inspectionSubmit =
  (id: string | number, data: any, cb?: any) => (dispatch: Dispatch) => {
    const { silent, ...other } = data;
    dispatch({
      isAsyncCall: true,
      payload: {
        jobId: id,
        silent,
      },
      type: Types.INSPECTION_SUBMIT,
      asyncCall: () => {
        return apiInspectionSubmit(id, other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
