import { Dispatch } from 'redux';
import {
  apiGetSectionResult,
  apiUploadDocumentList,
  apiUploadFiles,
} from 'src/api';

import Types from '../types/shared';

export const syncUpdateLanguage = (language) => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_UPDATE_LANGUAGE,
    payload: language,
  });
};
export const uploadFiles = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.UPLOAD_FILES,
    asyncCall: () => {
      return apiUploadFiles(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const uploadDocumentList = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.UPLOAD_DOCUMENT_LIST,
    asyncCall: () => {
      return apiUploadDocumentList(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getSectionResult = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_SECTION_RESULT,
    asyncCall: () => {
      return apiGetSectionResult();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
