export const getInitalValues = () => {
  return {
    quantity: 0,
    description: '',
    referenceMedia: [],
    classification: null,
    defectName: null,
    defectGroup: null,
  };
};
