import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import classes from './Summary.module.scss';

interface Props {
  className?: string;
  data: any;
}

const Summary = ({ className, data }: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  return (
    <div className={cn(classes.wrapper, className)}>
      <ul>
        <li>
          <span>{t('text.Inspection Level', { ns: 'common' })}</span>
          <span>{data?.inspectionLevel}</span>
        </li>
        <li>
          <span>{t('text.Sample Size', { ns: 'common' })}</span>
          <span className={classes.aql}>
            <span>
              {t('text.Critical', { ns: 'common' })}-{' '}
              {data?.sampleSize?.critical || 0}
            </span>
            <span>
              {t('text.Major', { ns: 'common' })}-{' '}
              {data?.sampleSize?.major || 0}
            </span>
            <span>
              {t('text.Minor', { ns: 'common' })}-{' '}
              {data?.sampleSize?.minor || 0}
            </span>
          </span>
        </li>
        <li>
          <span>{t('text.AQL', { ns: 'common' })}</span>
          <span className={classes.aql}>
            <span>
              {t('text.Critical', { ns: 'common' })}- {data?.aql?.critical || 0}
            </span>
            <span>
              {t('text.Major', { ns: 'common' })}- {data?.aql?.major || 0}
            </span>
            <span>
              {t('text.Minor', { ns: 'common' })}- {data?.aql?.minor || 0}
            </span>
          </span>
        </li>
        <li>
          <span>{t('text.Defectives Accepted', { ns: 'common' })}</span>
          <span className={classes.aql}>
            <span>
              {t('text.Critical', { ns: 'common' })}-{' '}
              {data?.defectivesAccepted?.critical || 0}
            </span>
            <span>
              {t('text.Major', { ns: 'common' })}-{' '}
              {data?.defectivesAccepted?.major || 0}
            </span>
            <span>
              {t('text.Minor', { ns: 'common' })}-{' '}
              {data?.defectivesAccepted?.minor || 0}
            </span>
          </span>
        </li>
      </ul>
    </div>
  );
};
export default Summary;
