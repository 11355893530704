import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import {
  FIELD_TYPE_MAPPING,
  INPUT_FIELD,
  LIST_TYPES,
  PARSED_UPLOAD_FIELDS,
  SECTION_1_IGNORE_FIELD_NAMES,
  SECTION_1_NAME,
  SECTION_1_PACKED_FIELD_NAME,
  SECTION_1_PACKED_PERCENT_FIELD_NAME,
  SECTION_1_PRODUCED_FIELD_NAME,
  SECTION_1_PRODUCED_PERCENT_FIELD_NAME,
  SECTION_2_NAME,
  SECTION_3_DEFECT_NAME,
  SECTION_3_NAME,
  SECTION_4_CL_FIELD_NAME,
  SECTION_4_NAME,
  SECTION_5_ADD_FIELD_NAMES,
  SECTION_5_NAME,
  SECTION_6_NAME,
  SECTION_IGNORE_FIELD_NAMES,
} from 'src/helpers/constants';
import { DEFECT_OBJ } from 'src/pages/JobRequests/JobDetail/data';
import {
  getFieldName,
  getListFieldName,
} from 'src/pages/JobRequests/JobDetail/utils';
import { translateFreeText } from 'src/utils/languagues';
import { AUTO_FILL_FORM, FORM_SETTINGS } from './constants';

const parseField = (field) => {
  return {
    ...field,
    originalType: field.type,
    type: FIELD_TYPE_MAPPING[field.type] || field.type,
    required: !field.optional,
    title: field.label,
    placeholder: field.placeholder,
  };
};
const getAddFieldLabel = (item, defaultName) => {
  return (item || [])[0]?.value || defaultName;
};
export const getSection1PercentValue = (value, limitValue) => {
  if (!value || value === '-') return 0;
  const limitPercent = parseInt(limitValue || 0);
  const percentValue = (parseInt(value || 0) / limitPercent) * 100;
  const displayPercentValue =
    percentValue > 100 ? percentValue : percentValue < 0 ? 0 : percentValue;
  return parseFloat(displayPercentValue.toFixed(2));
};
const getFields = (fields, sectionName) => {
  // const isEN = i18n?.language === 'en';
  const thisFields = [];
  fields.map((f) => {
    if (LIST_TYPES.includes(f.type)) {
      (f.items || []).map((item, index) => {
        thisFields.push(
          parseField({
            ...f,
            name: getFieldName(`${f.name}_${index + 1}`, sectionName),
            label: getAddFieldLabel(item, `${f.label} ${index + 1}`),
            tag: '',
            originalName: f.name,
            type: SECTION_4_NAME === sectionName ? 'SECTION' : f.type,
          })
        );
      });
    } else {
      const thisField = parseField({
        ...f,
        name: getFieldName(f.name, sectionName),
        originalName: f.name,
      });
      thisFields.push(thisField);
    }
  });
  return thisFields;
};
export const getFormSettings = (data = []) => {
  const returnFormSettings = [];
  (data || []).map((d, index) => {
    returnFormSettings[index] = {
      title: d.value,
      id: FORM_SETTINGS[index]?.id,
      fields: getFields(d.fields, d.name),
      name: d.name,
      enabled: d.enabled,
    };
  });
  return returnFormSettings;
};
const cleanFieldValue = (name, value) => {
  // TODO: fix render object from response
  if (name.includes('remark')) {
    return typeof value === 'object' ? '' : value;
  }
  return value;
};
const getSection5HasValue = (fields) => {
  const listItemHasValue = SECTION_5_ADD_FIELD_NAMES.map(
    (f) => find(fields, { name: f })?.value
  ).filter((v) =>
    typeof v === 'object' ? (isArray(v) ? v.length > 0 : !isEmpty(v)) : !!v
  );
  return listItemHasValue.length > 0;
};
export const getInitialValues = (formSettings) => {
  const returnValues = {};
  (formSettings || []).map((section) => {
    section.fields.map((field) => {
      const { nameIndex, sectionName } = getListFieldName(field.name);
      const isSection5 = sectionName === SECTION_5_NAME;
      if (
        [SECTION_3_DEFECT_NAME, SECTION_4_CL_FIELD_NAME].includes(
          field.originalName
        ) ||
        isSection5
      ) {
        const thisItem = isSection5 ? field.fields : field.items[nameIndex];
        const defectGroup = find(thisItem, { name: 'defectGroup' }) || {};
        const defectName = find(thisItem, { name: 'defectName' }) || {};
        const quantity = find(thisItem, { name: 'quantity' }) || {};
        const result = find(thisItem, { name: 'result' }) || {};
        const description = find(thisItem, { name: 'description' }) || {};
        const defectClassification =
          find(thisItem, { name: 'defectClassification' }) || {};
        const remark = find(thisItem, { name: 'remark' }) || {};
        const referenceMedia = find(thisItem, { name: 'referenceMedia' }) || {};
        returnValues[field.name] = {};
        const isSection3 = SECTION_3_DEFECT_NAME === field.originalName;
        const section3HasValue = isSection3 && quantity.value >= 0;
        const section4HasValue =
          SECTION_4_CL_FIELD_NAME === field.originalName &&
          (result.value || remark.value);
        const section5HasValue = isSection5 && getSection5HasValue(thisItem);
        if (isSection3) {
          returnValues[field.name].defectName = defectName.value;
          returnValues[field.name].defectGroup = defectGroup.value;
          if (section3HasValue) {
            returnValues[field.name].quantity = quantity.value;
            returnValues[field.name].description = description.value;
            returnValues[field.name].classification =
              defectClassification.value;
          }
        } else if (section4HasValue) {
          returnValues[field.name].result = result.value;
          returnValues[field.name].remark = remark.value;
        } else if (section5HasValue) {
          thisItem.map((fField) => {
            returnValues[field.name][fField.name] = fField.value;
          });
        }
        if (section3HasValue || section4HasValue || section5HasValue) {
          returnValues[field.name].referenceMedia = (
            referenceMedia.value || []
          ).map((id) => ({
            name: id,
            id,
          }));
        }
      } else if (PARSED_UPLOAD_FIELDS.includes(field.type)) {
        returnValues[field.name] = (field.value || []).map((id) => ({
          name: id,
          id,
        }));
      } else {
        const tValue = cleanFieldValue(field.name, field.value);
        returnValues[field.name] = tValue;
      }
      return true;
    });
    return true;
  });
  [
    'packingMarking_step_innerPackContent',
    'packingMarking_step_outerCartonContent',
    'packingMarking_step_sellingUnitContent',
  ].map((ss5Key) => {
    if (isEmpty(returnValues[ss5Key])) {
      returnValues[ss5Key] = '';
    }
  });
  return returnValues;
};
export const isSection1InputField = (
  sectionName,
  field,
  isOriginal = false,
  isCheckAll = false
) => {
  return (
    sectionName === SECTION_1_NAME &&
    (isOriginal ? field.type : field.originalType) === INPUT_FIELD &&
    (isCheckAll === true
      ? true
      : !SECTION_1_IGNORE_FIELD_NAMES.includes(
          isOriginal ? field.name : field.originalName
        ))
  );
};
export const getFieldSub = ({ sectionName, field, form, limit, t }) => {
  if (isSection1InputField(sectionName, field)) {
    const percentLabel = (field.title || '').split(' ')[0];
    const percentValue = getSection1PercentValue(
      form.getFieldsValue()[field.name],
      limit
    );
    return (
      <>
        <span>{translateFreeText(percentLabel, t)}</span>
        <span>{percentValue}%</span>
      </>
    );
  }
  return null;
};
export const getSubColor = ({ sectionName, field, fields, form, limit }) => {
  if (isSection1InputField(sectionName, field)) {
    const percentValue = getSection1PercentValue(
      form.getFieldsValue()[field.name],
      limit
    );
    if (field.originalName === SECTION_1_PRODUCED_FIELD_NAME) {
      const limitPercent =
        find(fields, { originalName: SECTION_1_PRODUCED_PERCENT_FIELD_NAME })
          .defaultValue || 0;
      return percentValue < parseInt(limitPercent) || percentValue > 100
        ? 'red'
        : 'green';
    } else if (field.originalName === SECTION_1_PACKED_FIELD_NAME) {
      const limitPercent =
        find(fields, { originalName: SECTION_1_PACKED_PERCENT_FIELD_NAME })
          .defaultValue || 0;
      return percentValue < parseInt(limitPercent) || percentValue > 100
        ? 'red'
        : 'green';
    }
  }
  return '';
};
export const getSubSection3Options = (inspectionFieldsGroup) => {
  const tArr = inspectionFieldsGroup || [];
  const section3Fields = find(tArr, { name: SECTION_3_NAME })?.fields || [];
  const defects =
    find(section3Fields, { name: SECTION_3_DEFECT_NAME })?.items || [];
  const defectGroupOptions: any[] = [];
  const defectGroupOptions1 = [];
  defects.forEach((subArr) => {
    const groupObj = find(subArr, { name: 'defectGroup' }) || {};
    const nameObj = find(subArr, { name: 'defectName' }) || {};
    const defectClassificationObj =
      find(subArr, { name: 'defectClassification' }) || {};
    defectGroupOptions1.push({
      value: groupObj.value,
      label: groupObj.value,
      names: [
        {
          value: nameObj.value,
          name: nameObj.value,
          classOptions: (defectClassificationObj.options || []).map(
            (option) => {
              if (typeof option === 'string') {
                return {
                  value: option,
                  label: option,
                };
              }
              return option;
            }
          ),
        },
      ],
    });
  });
  defectGroupOptions1.forEach((obj) => {
    const tFoundIndex = findIndex(defectGroupOptions, { value: obj.value });
    if (tFoundIndex < 0) {
      defectGroupOptions.push(obj);
    } else {
      if (
        findIndex(defectGroupOptions[tFoundIndex].names, {
          value: obj.names[0].name,
        }) < 0
      ) {
        defectGroupOptions[tFoundIndex].names.push(obj.names[0]);
      }
    }
  });
  return { defectGroupOptions };
};
export const getAddedDefectField = (data, existingLengh) => {
  const defaultObj = cloneDeep(DEFECT_OBJ);
  defaultObj.name = `${DEFECT_OBJ.name}${existingLengh + 1}`;
  defaultObj.label = data?.defectName;
  defaultObj.title = data?.defectName;
  defaultObj.items = defaultObj.items.map((obj) => {
    if (obj.name === 'defectName') {
      obj.value = data?.defectName;
    }
    if (obj.name === 'defectGroup') {
      obj.value = data?.defectGroup;
    }
    if (obj.name === 'defectClassification') {
      obj.value = data?.classification;
    }
    if (obj.name === 'referenceMedia') {
      obj.value = data?.referenceMedia;
    }
    if (obj.name === 'quantity') {
      obj.value = data?.quantity;
    }
    if (obj.name === 'description') {
      obj.value = data?.description;
    }
    return obj;
  });
  return defaultObj;
};
const getDefaultValue = (field, otherValue) => {
  const suffixKey = field.name.split('_step')[1];
  const defaultValue = AUTO_FILL_FORM[suffixKey];
  return defaultValue === undefined ? otherValue : defaultValue;
};
export const setAllSectionPending = (form, formSettings, otherObj) => {
  const setIsNoDefectFound = otherObj.setIsNoDefectFound;
  const isReset = otherObj.isReset;
  const tValues = form.getFieldsValue();
  formSettings.forEach((formEl) => {
    const tFields = formEl.fields.filter((field) => {
      const hideItem =
        SECTION_IGNORE_FIELD_NAMES.includes(field.originalName) ||
        field.show === false ||
        field.enabled === false;
      return !hideItem;
    });
    if (SECTION_1_NAME === formEl.name) {
      tFields.forEach((field) => {
        if (
          !field.name.includes('_result') &&
          !field.name.includes('_producedQuantity') &&
          !field.name.includes('_packedQuantity')
        ) {
          form.setFieldValue(
            field.name,
            isReset ? null : getDefaultValue(field, '0')
          );
        }
      });
    } else if (SECTION_2_NAME === formEl.name) {
      tFields.forEach((field) => {
        form.setFieldValue(
          field.name,
          isReset ? null : getDefaultValue(field, 'not_conform')
        );
      });
    } else if (SECTION_3_NAME === formEl.name) {
      tFields.forEach((field) => {
        if (field.name.includes('_defects_')) {
          form.setFieldValue(
            field.name,
            isReset
              ? null
              : {
                  defectName: tValues?.[field.name]?.defectName,
                  defectGroup: tValues?.[field.name]?.defectGroup,
                }
          );
        } else {
          form.setFieldValue(
            field.name,
            isReset ? null : getDefaultValue(field, '')
          );
        }
      });
      if (!isReset) setIsNoDefectFound(true);
    } else if (SECTION_4_NAME === formEl.name) {
      tFields.forEach((field) => {
        const defaultObj: any = {};
        ['result', 'remark', 'referenceMedia'].forEach((fKey) => {
          defaultObj[fKey] = getDefaultValue({ name: `_step_${fKey}` }, '0');
        });
        form.setFieldValue(field.name, isReset ? null : defaultObj);
      });
    } else if (SECTION_5_NAME === formEl.name) {
      tFields.forEach((field) => {
        const subFields = field.fields.filter((obj) =>
          SECTION_5_ADD_FIELD_NAMES.includes(obj.name)
        );
        const defaultObj: any = {};
        subFields.forEach((field) => {
          defaultObj[field.name] = getDefaultValue(
            { name: `_step_${field.name}` },
            '0'
          );
        });
        form.setFieldValue(field.name, isReset ? null : defaultObj);
      });
    } else if (SECTION_6_NAME === formEl.name) {
      tFields.forEach((field) => {
        form.setFieldValue(
          field.name,
          isReset ? null : getDefaultValue(field, '')
        );
      });
    }
  });
};
