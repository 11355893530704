import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import {
  FIELD_TYPES,
  LIST_TYPES,
  NORMAL_FIELDS,
  PARSED_UPLOAD_FIELDS,
  SECTION_1_PACKED_PERCENT_INPUT_FIELD_NAME,
  SECTION_1_PRODUCED_PERCENT_INPUT_FIELD_NAME,
  SECTION_3_DEFECT_NAME,
  SECTION_3_IGNORE_FIELD_NAMES,
  SECTION_3_NAME,
  SECTION_4_NAME,
  SECTION_5_ADD_FIELD_NAMES,
  SECTION_5_NAME,
  SUB_ADD_FIELD_NAMES,
  UPLOAD_TYPES,
} from 'src/helpers/constants';
import { getRequiredFieldOfStep } from './SubContent/SubInspection/components/StepsBar/utils';
import {
  getFormSettings,
  getInitialValues,
  getSection1PercentValue,
  isSection1InputField,
} from './SubContent/SubInspection/utils';

export const getFieldName = (name, sectionName) => {
  if (!name || !sectionName) return '';
  return `${sectionName}_step_${name}`;
};
export const getListFieldName = (name) => {
  if (!name || typeof name !== 'string') return {};
  const nameArr = name.split('_');
  const nameIndexString = nameArr[nameArr.length - 1];
  return {
    sectionName: nameArr[0],
    nameIndex: parseInt(nameIndexString, 10) - 1,
  };
};
const cleanAttachmentIds = (attachmentIds) => {
  if (typeof attachmentIds !== 'object') return [];
  return uniq(
    attachmentIds
      .map((id) => (typeof id === 'object' ? id.id || id.docId : id))
      .filter((id) => !!id)
  );
};
const getAttachmentIds = (formFiles, fieldName, documents) => {
  const attachmentIds = [];
  formFiles
    .filter((f) => f?.file?.originFileObj)
    .map((f, index) => {
      const tDocumentId = get(documents, `[${index}].id`);
      if (f.name === fieldName && tDocumentId) {
        attachmentIds.push(tDocumentId);
      }
    });
  return cleanAttachmentIds(attachmentIds);
};
const addValueToFields = (
  sections,
  values,
  documents,
  formFiles,
  section1Limit
) => {
  const returnSections = sections.map((section) => {
    section.fields = section.fields.map((field) => {
      const fieldName = getFieldName(field.name, section.name);
      if (section.name === SECTION_5_NAME) {
        const thisValue = values[fieldName] || {};
        field.fields = (field.fields || []).map((fField) => {
          if (fField.name === 'referenceMedia') {
            const thisFields = get(thisValue, 'referenceMedia') || [];
            const uploadAttachmentIds = getAttachmentIds(
              formFiles,
              fieldName,
              documents
            );
            fField.value = cleanAttachmentIds([
              ...thisFields,
              ...uploadAttachmentIds,
            ]);
          } else if (SECTION_5_ADD_FIELD_NAMES.includes(fField.name)) {
            fField.value = thisValue[fField.name] || '';
          }
          return fField;
        });
      } else if ([SECTION_3_NAME, SECTION_4_NAME].includes(section.name)) {
        if (!SECTION_3_IGNORE_FIELD_NAMES.includes(field.name)) {
          if (LIST_TYPES.includes(field.type)) {
            if (SECTION_3_NAME === section.name) {
              field.items = (field.items || []).map((item, i) => {
                const thisListName = `${fieldName}_${i + 1}`;
                const thisListValue = values[thisListName] || {};
                const section3Files =
                  get(thisListValue, 'referenceMedia') || [];
                item = item.map((subItem) => {
                  SUB_ADD_FIELD_NAMES.map((fName) => {
                    if (subItem.name === fName) {
                      subItem.value =
                        thisListValue[
                          fName === 'defectClassification'
                            ? 'classification'
                            : fName
                        ];
                    }
                  });
                  if (subItem.name === 'referenceMedia') {
                    const uploadAttachmentIds = getAttachmentIds(
                      formFiles,
                      thisListName,
                      documents
                    );
                    subItem.value = cleanAttachmentIds([
                      ...section3Files,
                      ...uploadAttachmentIds,
                    ]);
                  }
                  return subItem;
                });
                return item;
              });
            } else {
              field.items = (field.items || []).map((item, i) => {
                const thisListName = `${fieldName}_${i + 1}`;
                const thisListValue = values[thisListName] || {};
                const section3Files =
                  get(thisListValue, 'referenceMedia') || [];
                const quantityValue = get(thisListValue, 'quantity') || '0';
                const isSkipDefect =
                  quantityValue === '0' &&
                  [SECTION_3_DEFECT_NAME].includes(field.name);
                item = item.map((subItem) => {
                  SUB_ADD_FIELD_NAMES.map((fName) => {
                    if (subItem.name === fName) {
                      subItem.value =
                        fName !== 'quantity' && isSkipDefect
                          ? null
                          : thisListValue[
                              fName === 'defectClassification'
                                ? 'classification'
                                : fName
                            ];
                    }
                  });
                  if (isSkipDefect && subItem.name === 'referenceMedia') {
                    subItem.value = [];
                  } else if (
                    subItem.name === 'referenceMedia' &&
                    section3Files.length > 0
                  ) {
                    const uploadAttachmentIds = getAttachmentIds(
                      formFiles,
                      thisListName,
                      documents
                    );
                    subItem.value = isSkipDefect
                      ? []
                      : cleanAttachmentIds([
                          ...section3Files,
                          ...uploadAttachmentIds,
                        ]);
                  }
                  return subItem;
                });
                return item;
              });
            }
          } else {
            field.value = values[fieldName] || '';
          }
        }
      } else if (isSection1InputField(section.name, field, true, true)) {
        let thisValue = values[fieldName] || '';
        if (field.name === SECTION_1_PRODUCED_PERCENT_INPUT_FIELD_NAME) {
          const percentValue = getSection1PercentValue(
            values.quantityAvailable_step_producedQuantity,
            section1Limit
          );
          thisValue = percentValue;
        } else if (field.name === SECTION_1_PACKED_PERCENT_INPUT_FIELD_NAME) {
          const percentValue = getSection1PercentValue(
            values.quantityAvailable_step_packedQuantity,
            section1Limit
          );
          thisValue = percentValue;
        }
        field.value = thisValue;
      } else if (NORMAL_FIELDS.includes(field.type)) {
        field.value = values[fieldName] || '';
      } else if (UPLOAD_TYPES.includes(field.type)) {
        const attachmentIds = values[fieldName] || [];
        const uploadAttachmentIds = getAttachmentIds(
          formFiles,
          fieldName,
          documents
        );
        field.value = cleanAttachmentIds([
          ...attachmentIds,
          ...uploadAttachmentIds,
        ]);
      }
      return field;
    });
    return section;
  });
  const newItems = [];
  (values.addedDefects || [])
    .filter((obj) => Number(values?.[obj.name]?.quantity) >= 0)
    .forEach((obj) => {
      const uploadAttachmentIds = getAttachmentIds(
        formFiles,
        obj.name,
        documents
      );
      const tItems = obj.items.map((subObj) => {
        if (subObj.name === 'referenceMedia') {
          subObj.value = uploadAttachmentIds;
        }
        return subObj;
      });
      newItems.push(tItems);
    });
  const returnSections1 = returnSections.map((section) => {
    if (section.name === 'workmanship') {
      section.fields = section.fields.map((tField) => {
        if (tField.name === 'defects') {
          if (!tField.items?.length) {
            tField.items = [...newItems];
          } else {
            const itemNonQuantity = tField.items.filter(
              (tItems) =>
                !(Number(find(tItems, { name: 'quantity' })?.value) >= 0)
            );
            const itemHasQuantity = tField.items.filter(
              (tItems) => Number(find(tItems, { name: 'quantity' })?.value) >= 0
            );
            tField.items = [
              ...itemNonQuantity.map((tItems, itemIndex) => {
                const newItemArr = newItems[itemIndex];
                return newItemArr
                  ? tItems.map((tSub) => {
                      const foundObj = find(newItemArr, { name: tSub.name });
                      tSub.value = foundObj?.value;
                      return tSub;
                    })
                  : tItems;
              }),
              ...itemHasQuantity,
              ...newItems.filter(
                (_, itemIndex) => itemIndex >= itemNonQuantity.length
              ),
            ];
          }
        }
        return tField;
      });
    }
    return section;
  });
  // TODO: log Inspection payload request
  // console.log(`returnSections1`, returnSections1);
  return returnSections1;
};
export const mapFieldValues = ({
  inspectionListData,
  values,
  tInspectionId,
  isDraft,
  documents,
  formFiles,
  section1Limit,
}) => {
  const returnData = {
    inspectionPOItems: inspectionListData
      .map((d) => {
        if (String(d.id) === String(tInspectionId)) {
          const thisInspectionData = addValueToFields(
            JSON.parse(
              d.inspectionCriteria?.content ||
                d.inspectionCriteria?.scheme ||
                '[]'
            ),
            values,
            documents,
            formFiles,
            section1Limit
          );
          // eslint-disable-next-line no-console
          // console.log(`thisInspectionData ${d.id}`, thisInspectionData);
          return {
            id: d.id,
            inspectionCriteria: JSON.stringify(thisInspectionData),
          };
        }
        return {
          id: d.id,
          inspectionCriteria:
            d.inspectionCriteria?.content || d.inspectionCriteria?.scheme,
        };
      })
      .filter(
        (obj) =>
          !tInspectionId ||
          (!!tInspectionId &&
            !!isDraft &&
            String(tInspectionId) === String(obj.id))
      ),
  } as any;
  if (isDraft) {
    returnData.isDraft = true;
  }
  return returnData;
};
export const getUploadFields = (formSettings, values) => {
  const returnFields = [];
  formSettings.map((section) => {
    section.fields.map((field) => {
      if (
        (section.name === SECTION_3_NAME && field.type === FIELD_TYPES.ADD) ||
        ([SECTION_4_NAME, SECTION_5_NAME].includes(section.name) &&
          field.type === FIELD_TYPES.ADD1)
      ) {
        returnFields.push(field.name);
      } else if (PARSED_UPLOAD_FIELDS.includes(field.type)) {
        returnFields.push(field.name);
      }
      return true;
    });
    return true;
  });
  (values.addedDefects || []).forEach((obj) => {
    const tMedia =
      find(obj.items || [], { name: 'referenceMedia' })?.value || [];
    if (tMedia.length > 0) {
      returnFields.push(obj.name);
    }
  });
  return returnFields;
};
export const getFormUploadFiles = (names = [], values) => {
  const files = [];
  names.map((name) => {
    const { sectionName } = getListFieldName(name);
    if (
      [SECTION_3_NAME, SECTION_4_NAME, SECTION_5_NAME].includes(sectionName)
    ) {
      const thisValue = values[name] || {};
      (thisValue.referenceMedia || []).map((file) => {
        files.push({
          name,
          file,
        });
      });
    } else {
      (values[name] || []).map((file) => {
        files.push({
          name,
          file,
        });
      });
    }
  });
  return files;
};
export const getInspectionContent = (inspectionData) => {
  const inspectionSchemaString =
    inspectionData.inspectionCriteria?.scheme || '';
  const inspectionSchema = inspectionSchemaString
    ? JSON.parse(inspectionSchemaString)
    : [];
  const inspectionContentString =
    inspectionData.inspectionCriteria?.content || '';
  const inspectionContent = inspectionContentString
    ? JSON.parse(inspectionContentString)
    : [];
  return !isEmpty(inspectionContent) ? inspectionContent : inspectionSchema;
};
export const getFormSettingsData = (
  job,
  inspectionId,
  inspectionData = null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  t,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  i18n
) => {
  const intInspectionId = parseInt(inspectionId, 10);
  const inspectionListData = inspectionData
    ? []
    : job.inspectionPOReportGroup?.inspectionPOItems || [];
  const thisInspectionData = inspectionData
    ? inspectionData
    : find(inspectionListData, {
        id: intInspectionId,
      }) || {};
  const inspectionFieldsGroup = getInspectionContent(thisInspectionData);
  return {
    formSettings: getFormSettings(inspectionFieldsGroup),
    thisInspectionData,
    inspectionListData,
    inspectionFieldsGroup,
    nextInspection:
      inspectionListData[
        findIndex(inspectionListData, { id: intInspectionId }) + 1
      ],
  };
};
const getFieldDocuments = (value, documents) => {
  return value
    .map((id) => {
      const thisId = id.id || id.docId || id;
      const newObj = find(documents, { id: thisId }) || {};
      if (newObj.fileName) {
        newObj.name = newObj.fileName.split(',')[1];
      }
      return newObj;
    })
    .filter((doc) => !!doc);
};
export const setMediaFieldsValue = (formSettings, documents, form) => {
  const fieldValueObjs = {};
  const values = form.getFieldsValue();
  formSettings.map((section) => {
    section.fields.map((field) => {
      const thisValue = values[field.name];
      if (
        [SECTION_3_NAME, SECTION_4_NAME, SECTION_5_NAME].includes(section.name)
      ) {
        const isSection5 = section.name === SECTION_5_NAME;
        const { nameIndex } = getListFieldName(field.name);
        const thisItem = isSection5
          ? field.fields
          : get(field, `items[${nameIndex}]`) || [];
        const foundMedia = find(thisItem, { name: 'referenceMedia' }) || {};
        const newDocuments = getFieldDocuments(
          foundMedia.value || [],
          documents
        );
        const thisFormValue = values[field.name] || {};
        if (typeof thisFormValue === 'object' && newDocuments.length > 0) {
          thisFormValue.referenceMedia = [...newDocuments];
        }
        if (!field.name.includes('remark')) {
          fieldValueObjs[field.name] = thisFormValue;
        }
      } else if (
        PARSED_UPLOAD_FIELDS.includes(field.type) &&
        thisValue &&
        thisValue.length > 0
      ) {
        fieldValueObjs[field.name] = getFieldDocuments(thisValue, documents);
      }
      return true;
    });
    return true;
  });
  form.setFieldsValue(fieldValueObjs);
};
export const calculateInspectionDetail = (inspectionData, t, i18n) => {
  const { formSettings } = getFormSettingsData(
    null,
    null,
    inspectionData,
    t,
    i18n
  );
  const values = getInitialValues(formSettings);
  let allRequiredFieldsCount = 0;
  let allCompletedFieldsCount = 0;
  [1, 2, 3, 4, 5, 6].map((step) => {
    const fieldObj = getRequiredFieldOfStep(step, values, formSettings);
    const isStepDisabled = !formSettings[step - 1]?.enabled;
    allRequiredFieldsCount += isStepDisabled ? 0 : fieldObj.fields;
    allCompletedFieldsCount += isStepDisabled ? 0 : fieldObj.completedFields;
    return true;
  });
  return {
    requiredFields: allRequiredFieldsCount,
    completedFields: allCompletedFieldsCount,
  };
};
export const calculateAllInspections = (inspectionItems, t, i18n) => {
  const returnObj = {} as any;
  let completedLength = 0;
  inspectionItems.map((inspectionData) => {
    const { requiredFields, completedFields } = calculateInspectionDetail(
      inspectionData,
      t,
      i18n
    );
    returnObj[inspectionData.id] = {
      requiredFields,
      completedFields,
    };
    if (requiredFields === completedFields) {
      completedLength++;
    }
    return true;
  });
  if (completedLength === inspectionItems.length) {
    returnObj.isCompletedAll = true;
  }
  return returnObj;
};
export const getSubmitBR = (inspectionPOItems) => {
  const returnObj: any = {
    isDraft: false,
    inspectionPOItems: inspectionPOItems.map((item) => {
      return {
        id: item.id,
        inspectionCriteria:
          item.inspectionCriteria?.content || item.inspectionCriteria?.scheme,
      };
    }),
  };
  return returnObj;
};
export const getOverallProgress = (inspectionPOItems, t, i18n) => {
  let totalRequiredFields = 0;
  let totalCompletedfields = 0;
  const calAllInspectionPO = calculateAllInspections(
    inspectionPOItems,
    t,
    i18n
  );
  inspectionPOItems.map((item) => {
    totalRequiredFields =
      totalRequiredFields + (calAllInspectionPO[item.id]?.requiredFields || 0);
    totalCompletedfields =
      totalCompletedfields +
      (calAllInspectionPO[item.id]?.completedFields || 0);
  });
  return parseFloat(
    ((totalCompletedfields / totalRequiredFields) * 100).toFixed(1)
  );
};
