import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoSvg } from 'src/assets/icons/Info.svg';
import { FormItem } from 'src/components';
import { Checkbox, Input } from 'src/components/FormFields';
import classes from './Section1ResultCheck.module.scss';

const Section1ResultCheck = ({ checked, onChange, name }) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  return (
    <>
      <FormItem name={name} className="hide">
        <Input />
      </FormItem>
      <div className={classes.content}>
        <div>
          <InfoSvg />
        </div>
        <div>
          <span>{t('text.autoFillPlaceholder', { ns: 'common' })}</span>
          <div>
            <Checkbox checked={checked} onChange={onChange} />
            <span>
              {t('text.Set all sections to Pending', { ns: 'common' })}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section1ResultCheck;
