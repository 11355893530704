import { Request } from './request';

export const apiUploadFiles = async (data) => {
  return Request.call({
    url: '/v1/upload/multiple/file',
    method: 'POST',
    data,
    multipart: true,
  });
};
export const apiUploadDocumentList = async (data) => {
  return Request.call({
    url: '/v1/micro/document-list',
    method: 'POST',
    data,
  });
};
export const apiGetDocumentList = async (ids) => {
  const appendUrl = ids.map((id) => `ids=${id}`).join('&');
  return Request.call({
    url: `/v1/micro/document-list/url?${appendUrl}`,
    method: 'GET',
    params: {},
  });
};
export const apiGetSectionResult = async () => {
  return Request.call({
    url: `/v1/micro/inspections/iqc/section-result`,
    method: 'GET',
    params: {},
  });
};
