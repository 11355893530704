/* eslint-disable no-debugger */
import { saveAs } from 'file-saver';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { apiDownloadAllDocuments } from 'src/api';
import { ReactComponent as CheckoutBigSvg } from 'src/assets/icons/CheckoutBig.svg';
import { ReactComponent as DocumentSvg } from 'src/assets/icons/Document.svg';
import { ReactComponent as InfoSvg } from 'src/assets/icons/Info.svg';
import { Button, CheckUnCheckSvg } from 'src/components';
import { PageHeader } from 'src/components/Page';
import { STATUS_VALUES } from 'src/helpers/constants';
import {
  useCurrentPath,
  useJobDetailStatus,
  useUploadFiles,
} from 'src/helpers/hooks';
import toast from 'src/helpers/toast';
import Job from 'src/interfaces/Job';
import Heading from 'src/pages/JobRequests/components/Heading';
import { actionCb } from 'src/utils/action';
import { getCheckedInTime } from 'src/utils/date';
import { dataURLtoFile } from 'src/utils/file';
import classes from './JobDetail.module.scss';
import Loading from './Loading';
import SubDocument from './SubContent/SubDocument';
import SubInfo from './SubContent/SubInfo';
import SubInspection from './SubContent/SubInspection';
import { getInitialValues } from './SubContent/SubInspection/utils';
import SubRemark from './SubContent/SubRemark';
import SubSubmissionPreview from './SubContent/SubSubmissionPreview';
import SubmitButton from './SubmitButton';
import Actions from './components/Actions';
import CheckIn from './components/CheckIn';
import ActionModal from './components/CheckIn/ActionModal';
import Document from './components/Document';
import Information from './components/Information';
import RejectForm from './components/RejectForm';
import { useActions, useIndexData } from './selectorData';
import {
  calculateAllInspections,
  getFormSettingsData,
  getFormUploadFiles,
  getOverallProgress,
  getSubmitBR,
  getUploadFields,
  mapFieldValues,
  setMediaFieldsValue,
} from './utils';

let tForm = null;
let tempSignedObj = null;
let mounted = false;

const JobDetail = () => {
  const { t, i18n } = useTranslation(['jobRequests', 'common']);
  const dontSaveDraft = false;
  const matchParams = useParams();
  const jobId = matchParams?.id || '';
  const inspectionId = matchParams?.inspectionId || '';
  const {
    getJobDetail,
    rejectJob,
    acceptJob,
    checkInJob,
    inspectionSubmit,
    getJobDocuments,
    checkOutJob,
    getSectionResult,
  } = useActions();
  const {
    jobDetail,
    currentUserId,
    companyId,
    checkoutLoading,
    sectionResult,
  } = useIndexData();
  const job = jobDetail as Job;
  const documentList = job?.documentList || [];
  const remark = job?.remark || '';
  const inspectionPOItems =
    job.inspectionPOReportGroup?.inspectionPOItems || [];
  const calAllInspectionPO = calculateAllInspections(
    inspectionPOItems,
    t,
    i18n
  );
  const tInspectionPOItems = find(inspectionPOItems, {
    id: Number(inspectionId),
  });
  const [startCheckout, setStartCheckout] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isRejectAction, setIsRejectAction] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [isSavedDraft, setIsSavedDraft] = useState(false);
  const [isDownloadAll, setIsDownloadAll] = useState(false);
  const [poLoading, setPoLoading] = useState<any>({});
  const poLoadingIds = Object.keys(poLoading).filter((key) => !!poLoading[key]);
  const isPOLoading = poLoadingIds.length > 0;
  const jobStatus = job.status;
  const isCheckedIn = !!job.checkinTime;
  const isCheckedOut = !!job.checkoutTime;
  const isScheduled = jobStatus === STATUS_VALUES.SCHEDULED;
  const isCompleted = jobStatus === STATUS_VALUES.COMPLETED;
  const navigate = useNavigate();
  const currentPath = useCurrentPath();
  const { uploadFilesFn } = useUploadFiles();
  const isDetail = currentPath === '/jobs/:id';
  const isInfo = currentPath === '/jobs/:id/info';
  const isDocument = currentPath === '/jobs/:id/document';
  const isRemark = currentPath === '/jobs/:id/remark';
  const isInspection = currentPath === '/jobs/:id/inspection/:inspectionId';
  const isSubmissionPreview = currentPath === '/jobs/:id/submission-preview';
  const { canCheckout, canSubmitReport, canViewReport } = useJobDetailStatus();
  const {
    formSettings,
    thisInspectionData,
    inspectionListData,
    inspectionFieldsGroup,
    nextInspection,
  } = getFormSettingsData(job, inspectionId, null, t, i18n);
  const section1Limit = thisInspectionData.orderQty;
  // Render title of job detail
  const title = isRejectAction
    ? t('jobDetail.reject.title')
    : isInspection
    ? thisInspectionData.productProfile?.name
    : isInfo
    ? job.jobNoList?.jobNo
    : isDocument
    ? t('jobDetail.document.title')
    : isRemark
    ? t('jobDetail.remark.title')
    : isSubmissionPreview
    ? t('jobDetail.preview.title')
    : t('jobDetail.root.title');
  const goToDetail = () => {
    navigate(`/jobs/${jobId}`);
  };
  const goToSub = (key, poId?: string | number) => () => {
    const tUrl = `/jobs/${jobId}/${key}`;
    if (key.startsWith('inspection')) {
      setPoLoading({
        [poId]: true,
      });
      getJobDetail(
        jobId,
        actionCb(
          () => {
            setPoLoading({
              [poId]: false,
            });
            setTimeout(() => {
              navigate(tUrl);
            }, 100);
          },
          () => {
            setPoLoading({
              [poId]: false,
            });
            navigate(tUrl);
          }
        ),
        {
          silent: true,
        }
      );
    } else {
      navigate(tUrl);
    }
  };
  const reloadInspectionDocuments = (tJobDetail?: any, tInspectionId?: any) => {
    const thisJobDetail = tJobDetail === '' ? job : tJobDetail;
    const actualInspectionId = tInspectionId || inspectionId;
    getJobDocuments(
      jobId,
      actualInspectionId,
      actionCb((docRes) => {
        const thisDocuments = docRes.data?.list || [];
        if (thisDocuments.length > 0 && tForm && !isEmpty(thisJobDetail)) {
          const thisFormSettings = getFormSettingsData(
            thisJobDetail,
            actualInspectionId,
            null,
            t,
            i18n
          ).formSettings;
          setMediaFieldsValue(thisFormSettings, thisDocuments, tForm);
        }
      })
    );
  };
  const reloadJobDetail = (cb?: any, forceHideDocuments?: boolean) => {
    getJobDetail(
      jobId,
      actionCb((jobRes) => {
        if (!forceHideDocuments && !!inspectionId) {
          reloadInspectionDocuments(jobRes.data || {});
        }
        if (cb) cb(jobRes);
      })
    );
  };
  const saveDraftFn = async (tValues?: any, cb?: any, silent = false) => {
    if (!isCompleted) {
      if (!dontSaveDraft) {
        setIsSavingDraft(true);
      }
      const values = tValues || tForm.getFieldsValue();
      const uploadFieldNames = getUploadFields(formSettings, values);
      const formFiles = getFormUploadFiles(uploadFieldNames, values);
      const documents = await uploadFilesFn(
        formFiles.filter((f) => !!f.file?.originFileObj && !f.file?.uploaded),
        companyId,
        tForm
      );
      const br = mapFieldValues({
        inspectionListData,
        values,
        tInspectionId: inspectionId,
        isDraft: true,
        documents,
        formFiles,
        section1Limit,
      });
      br.silent = silent;
      if (!dontSaveDraft) {
        inspectionSubmit(jobId, br, () => {
          tForm.setFieldValue('addedDefects', []);
          setIsSavingDraft(false);
          setIsSavedDraft(true);
          if (cb) cb();
        });
      }
    }
  };
  const handleSubmit = async (cb = null) => {
    setSubmitLoading(true);
    const tSignedFile =
      (tempSignedObj?.signature?.val
        ? dataURLtoFile(tempSignedObj?.signature?.val, 'signature.png')
        : tempSignedObj?.signatureFile) || '';
    let eSignatureDocumentListId = '';
    if (tSignedFile) {
      tSignedFile.originFileObj = tSignedFile.originFileObj || tSignedFile;
      const documents = await uploadFilesFn([{ file: tSignedFile }], companyId);
      eSignatureDocumentListId = documents?.[0]?.id;
    }
    const br = getSubmitBR(inspectionPOItems);
    if (eSignatureDocumentListId) {
      br.eSignatureDocumentListId = eSignatureDocumentListId;
      br.eSignatureType = tempSignedObj?.signature?.val ? 'draw' : 'file';
    }
    if (tempSignedObj?.name) {
      br.signerName = tempSignedObj.name;
    }
    if (tempSignedObj?.title) {
      br.signerTitle = tempSignedObj.title;
    }
    if (tempSignedObj?.companyName) {
      br.signerCompany = tempSignedObj.companyName;
    }
    inspectionSubmit(jobId, br, (res) => {
      setSubmitLoading(false);
      if (res) {
        goToDetail();
        reloadJobDetail(null, true);
      } else {
        toast.error('Submit Inspection failed. Please try later!');
      }
      if (cb) cb();
    });
  };
  const actionModalProps = {
    isCheck: startCheckout,
    onClose: () => {
      setStartCheckout(false);
    },
  };
  const needToSubmit = calAllInspectionPO.isCompletedAll && !isCompleted;

  useEffect(() => {
    if (inspectionId && !!mounted) {
      reloadInspectionDocuments('', inspectionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionId]);
  useEffect(() => {
    mounted = false;
    tForm = null;
    tempSignedObj = null;
    setTimeout(() => {
      reloadJobDetail((tRes) => {
        mounted = true;
        if (inspectionId) {
          setTimeout(() => {
            reloadInspectionDocuments(tRes?.data, inspectionId);
          }, 0);
        }
      }, true);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper}>
      <Loading />
      <PageHeader
        isCompleted={isCompleted}
        insidePageInner={!isDocument && !isRemark}
        sticky
        onBack={async () => {
          if (isRejectAction) {
            setIsRejectAction(false);
            reloadJobDetail(null, true);
          } else {
            if (isInspection) {
              if (!isCompleted && isCheckedIn) {
                await saveDraftFn(null, () => {
                  goToDetail();
                  reloadJobDetail(null, true);
                });
              } else {
                goToDetail();
                reloadJobDetail(null, true);
              }
            } else {
              if (isDetail) {
                navigate('/jobs');
              } else {
                goToDetail();
              }
              if (!isSubmissionPreview && !isDetail) {
                reloadJobDetail(null, true);
              }
            }
          }
        }}
        title={title}
        saveLoading={isSavingDraft}
        itemNo={isInspection ? tInspectionPOItems?.productProfile?.itemNo : ''}
        onSave={
          isInspection && isCheckedIn
            ? () => {
                saveDraftFn(
                  '',
                  () => {
                    reloadJobDetail((res) => {
                      const tInitialValues = getInitialValues(
                        getFormSettingsData(
                          res.data,
                          inspectionId,
                          null,
                          t,
                          i18n
                        )?.formSettings
                      );
                      tForm.setFieldsValue(tInitialValues);
                    });
                  },
                  true
                );
              }
            : undefined
        }
        saved={isSavedDraft}
        className={classes.pageHeader}
      />
      {isRejectAction ? (
        <RejectForm
          onSubmit={(reason, cb) => {
            rejectJob(
              jobId,
              reason,
              actionCb(() => {
                reloadJobDetail();
                setIsRejectAction(false);
                if (cb) cb();
              })
            );
          }}
        />
      ) : isInfo ? (
        <SubInfo
          rejectReason={job.rejectReason}
          data={job}
          currentUserId={currentUserId}
        />
      ) : isInspection ? (
        <SubInspection
          formSettings={formSettings}
          thisInspectionData={thisInspectionData}
          onForm={(val) => {
            tForm = val;
          }}
          setIsSavedDraft={setIsSavedDraft}
          inspectionFieldsGroup={inspectionFieldsGroup}
          nextInspection={nextInspection}
          onNext={async (values, cb) => {
            // if (!isCompleted) {
            //   await saveDraftFn(values, null, true);
            // }
            if (isCheckedIn) {
              if (cb) cb();
              if (nextInspection?.id) {
                goToSub(`inspection/${nextInspection.id}`, nextInspection.id)();
              } else {
                goToDetail();
                reloadJobDetail(null, true);
              }
            }
          }}
          jobId={jobId}
          inspectionId={inspectionId}
          job={job}
          disabled={!isCheckedIn}
          sectionResult={sectionResult}
          getSectionResult={getSectionResult}
        />
      ) : isSubmissionPreview ? (
        <SubSubmissionPreview
          inspectionPOItems={inspectionPOItems}
          calAllInspectionPO={calAllInspectionPO}
          needToSubmit={needToSubmit}
          onSubmit={(tSigned) => {
            tempSignedObj = tSigned;
            setStartCheckout(true);
          }}
          onEdit={(poId, stepNumber) => {
            goToSub(`inspection/${poId}?step=${stepNumber}`, poId)();
          }}
          submitLoading={submitLoading}
          job={job}
        />
      ) : isDocument ? (
        <SubDocument
          documentList={documentList}
          onDownloadAll={async () => {
            if (!isDownloadAll) {
              setIsDownloadAll(true);
              const res = await apiDownloadAllDocuments(jobId);
              const blob = new Blob([res.data], {
                type: 'application/octet-stream',
              });
              const filename = `${jobId}.zip`;
              saveAs(blob, filename);
              setIsDownloadAll(false);
            }
          }}
          allLoading={isDownloadAll}
        />
      ) : isRemark ? (
        <SubRemark remark={remark} />
      ) : (
        <>
          <Information
            className={classes.information}
            onMore={goToSub('info')}
            data={job}
            currentUserId={currentUserId}
            overallProgress={getOverallProgress(inspectionPOItems, t, i18n)}
          />
          <div className={classes.inner}>
            {(documentList.length > 0 || !!remark) && (
              <>
                <Heading>{t('jobDetail.root.additionalInformation')}</Heading>
                {documentList.length > 0 && (
                  <Document
                    icon={<DocumentSvg />}
                    title={t('jobDetail.document.subTitle')}
                    des={`${documentList.length} ${t('text.file', {
                      ns: 'common',
                    })}${
                      documentList.length > 1 && i18n.language === 'en'
                        ? 's'
                        : ''
                    }`}
                    onMore={goToSub('document')}
                  />
                )}
                {!!remark && (
                  <Document
                    icon={<InfoSvg />}
                    title={t('jobDetail.remark.title')}
                    des={t('jobDetail.remark.payAttention')}
                    onMore={goToSub('remark')}
                  />
                )}
              </>
            )}
            {inspectionPOItems.length > 0 && (
              <>
                <Heading>{t('jobDetail.root.inspectionItems')}</Heading>
                {inspectionPOItems.map((poItem) => {
                  const { requiredFields, completedFields } =
                    calAllInspectionPO[poItem.id];
                  return (
                    <Document
                      icon={
                        <CheckUnCheckSvg
                          active={requiredFields === completedFields}
                        />
                      }
                      key={poItem.id}
                      title={poItem.productProfile?.name}
                      itemNo={poItem.productProfile?.itemNo}
                      des={`${t(
                        'jobDetail.root.completed'
                      )}: ${completedFields}/${requiredFields}`}
                      onMore={() => {
                        // eslint-disable-next-line no-debugger
                        if (canSubmitReport && !isCompleted) {
                          goToSub(`inspection/${poItem.id}`, poItem.id)();
                        } else if (canViewReport) {
                          goToSub(`submission-preview`)();
                        }
                      }}
                      disabled={isPOLoading}
                      loading={String(poLoadingIds?.[0]) === String(poItem.id)}
                    />
                  );
                })}
              </>
            )}
            <SubmitButton jobId={jobId} needToSubmit={needToSubmit} />
          </div>
          <Actions
            onReject={() => {
              setIsRejectAction(true);
            }}
            onAccept={() => {
              acceptJob(
                jobId,
                actionCb(() => {
                  reloadJobDetail();
                  setIsAccepted(true);
                })
              );
            }}
            isAccepted={isAccepted || isScheduled}
            onAcceptSubmit={(cb) => {
              checkInJob(
                jobId,
                actionCb(() => {
                  reloadJobDetail();
                  setIsAccepted(false);
                  if (cb) cb();
                })
              );
            }}
            onAcceptBack={() => {}}
          />
          {isCheckedIn && !isCheckedOut && canCheckout && (
            <CheckIn
              timeString={job.checkinTime}
              onSubmit={(cb) => {
                checkOutJob(
                  jobId,
                  actionCb(() => {
                    reloadJobDetail();
                    if (cb) cb();
                  })
                );
              }}
              submitLoading={checkoutLoading}
            />
          )}
        </>
      )}
      <ActionModal {...actionModalProps}>
        <div className={classes.submitModalContent}>
          <CheckoutBigSvg />
          <p>
            {t('jobDetail.inspection.checkoutTitle.1')}
            <br />
            {t('jobDetail.inspection.checkoutTitle.2')} {getCheckedInTime()}
          </p>
        </div>
        <Button
          type="primary"
          size="large"
          onClick={() => {
            handleSubmit(() => {
              setStartCheckout(false);
            });
          }}
          fullWidth
          loading={submitLoading}
        >
          {t('buttons.okay', { ns: 'common' })}
        </Button>
      </ActionModal>
    </div>
  );
};

export default JobDetail;
